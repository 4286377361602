@import "styles/theme.scss";

.code-add-modal {
  .title {
    margin-top: 0px;
  }
  .ant-modal-body {
    padding: 2.5em 3em;
    .add-code {
      margin-top: 36px;
      p {
        color: $label-color-light;
        font-size: 14px;
        margin-bottom: 3px;
      }
      .ant-input {
        border: 1px solid #e2e3e8;
        box-sizing: border-box;
        border-radius: 4px;
        height: 120px;
        resize: none;
      }
    }
    .button-wrapper {
      .ant-btn:nth-child(1) {
        margin-right: 1em;
      }
      .ant-btn:nth-child(2) {
        margin-left: 1em;
      }
    }
  }
}
