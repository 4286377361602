@import "styles/theme.scss";

.profile {
  --profile-padding: 10px;

  .profile-header {
    margin-bottom: 2em;
  }

  .ant-btn.change-btn{
    padding: 0 8px;
    font-weight: 500;
    color: $primary-color;
    display: inline-block;
    margin-left: 10px;
  }
  .link-btn {
    padding: 0;
    font-weight: 500;
    color: $primary-color-text;

    &.cancel {
      color: $light-link-border-color;
      opacity: 0.8;
    }

    span {
      text-decoration: underline;
    }

    &:hover{
      color: inherit;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $tab-border;
    margin-top: 20px;
  }


  .password-actions {
    padding-left: calc(var(--profile-padding) * 2);
    gap: 20px;
  }


  .profile-form {
    background: $white-color;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(3, 20, 63, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0 !important;

    .profile-actions {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding: calc(var(--profile-padding) * 2);
    }
    
    .delete-btn {
      margin-top: 30px;
    }

    .save-btn,
    .reset-btn{
      width: calc(50% - 10px);
    }

    .content {
      padding: var(--profile-padding);

      .input-container {
        height: fit-content;
        margin-bottom: 0px;
        width: 100%;

        input {
          height: 32px;
        }

        .ant-progress {
          .ant-progress-inner {
            height: 64px !important;
            width: 64px !important;
          }
        }

        .ant-select-single {
          .ant-select-selector {
            .ant-select-selection-search {
              right: 0 !important;
              left: 0 !important;
            }
          }
        }

        .ant-select:not(.ant-select-customize-input) {
          .ant-select-selector {
            height: 34px !important;

            .ant-select-selection-search-input {
              padding: 4px 11px !important;
            }
          }
        }

      }
    }

    .ant-form-item {
      padding: var(--profile-padding);
      margin-bottom: 0;

      .ant-form-item-label {
        padding-bottom: 4px;

        label {
          color: $label-color-light;
          display: flex;
          justify-content: space-between;
        }
      }

      .ant-form-item-explain {
        min-height: 0;
      }
    }
  }

  @include rwd-min(700) {
    .profile-form {
      .profile-actions {
        justify-content: flex-start;

        .ant-btn {
          width: calc(33.33% - 20px);
          max-width: 180px;
          margin-top: 0;
        }

        .reset-btn {
          order: 3;
          width: auto;
        }

        .delete-btn{
          margin-left: auto;
        }
      }
    }
  }


  @include rwd-min(1200) {
    --profile-padding: 20px;

    .profile-form {
      .ant-form-item {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .change-password {
        padding-top: 0;
        padding-bottom: 0;

        .ant-form-item {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}