@import 'styles/theme.scss';
.toolbar-side-menu {
  --section-padding: 16px;
  height: calc(var(--height-100) - 64px);
  z-index: 1000;
  background: $white-color !important;

  .toolbar-side-menu-closing-mask {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $loader-overlay-background;
    right: 0;
    z-index: -1;
  }

  @media screen and (max-width: 1299px) {
    position: fixed !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    &.main-menu {
      width: 300px !important;
      max-width: 300px !important;
    }
  }
  @media screen and (min-width: 767px) {
    .toolbar-side-menu-closing-mask {
      display: none;
    }
  }
  @media screen and (min-width: 1299px) {
    &.main-menu {
      flex-basis: 26% !important;
      max-width: 26% !important;
      width: 23% !important;
    }
  }

  &.content-table-side-menu {
    left: 0;
  }
  &.main-menu {
    right: 0;
    &.ant-layout-sider-is-collapsed {
      overflow-y: unset;
      transform: translateX(100%);
      position: fixed !important;
    }
  }

  .ant-tabs {
    overflow: visible !important;
  }
  .ant-tabs-nav{
    margin: 0 !important;
  }

  @include rwd(1300) {
    .elements-menu {
      .items {
        flex-flow: column wrap;
        margin-bottom: 20px !important;
        .ant-col {
          margin: 0 auto;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  @include lg {
    .ant-layout-content {
      .ant-col {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .tabs {
    height: calc(var(--height-100) - 66px);
    background-color: $white-color;
    .ant-tabs-content-holder {
      @media screen and (min-width: 1100px) {
        @include custom-scrollbar;
      }
      max-height: calc(100% - 60px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    .ant-tabs-nav-wrap{
      padding: var(--section-padding);
    }

    .ant-tabs-nav-list {
      flex-grow: 1;
      flex-wrap: wrap;
      justify-content: space-evenly;
      border-radius: 4px;
      padding: 4px;
      background: $light-color;
      .ant-tabs-tab {
        margin: 0;
        padding: 7px;
        .ant-tabs-tab-btn {
          color: $primary-color-text;
          opacity: 0.8;
          transition: font-weight ease-in-out;
          user-select: none;
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-weight: 600;
            color: $primary-color-text;
            opacity: 1;
          }
        }

        &.ant-tabs-tab-active {
          border-radius: 4px;
          background: $white-color;
        }
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav {
      &::before {
        display: none;
      }
    }
  }

  .ant-layout-sider-trigger {
    width: inherit;
  }

  .ant-layout-sider-collapse {
    position: absolute;
    z-index: 1;
    background: white;
    text-align: center;
    border-radius: 4px 0 0 4px;
    width: 50px;
    height: 50px;
    left: 1px;
    top: 60px;
    transform: translateX(-100%);
    @extend .flex-center;


    .anticon {
      height: 2em;
      width: 2em;
      font-size: 18px;
      padding: 0.5em;

      &.ant-layout-sider-collapse-expand {
        transform: rotate(180deg);
      }
    }
  }

  .ant-tabs {
    .ant-tabs-nav-list {
      justify-content: space-between;
      .ant-tabs-tab {
        width: 50%;
        justify-content: center;
        display: flex;
        @media (min-width: 1366px) {
          width: auto;
        }
        @media (min-width: 1500px) {
          width: 25%;
        }
      }
    }
  }

  
  @media screen and (min-width:1024px) {
    --section-padding: 32px;
  }
}


@media screen and (min-width: 1500px) {
  .content-table-active .toolbar-side-menu {
    flex-basis: 23% !important;
    max-width: calc(100vw - 1180px) !important;
  }
}
