@import "styles/theme.scss";

.user-page {
  .page-header {
    margin-bottom: 2em;
  }

  .ant-card {
    box-shadow: $box-shadow;
    border-radius: 4px;
    .ant-card-body {
      min-height: 70vh;
      padding: 20px;
    }
  }

  h3,
  h4,
  p {
    color: $primary-color;
  }

  @include rwd-min(1200) {
    padding: 20px 50px;

    .ant-card {
      .ant-card-body {
        padding: 40px;
      }
    }
  }
}

