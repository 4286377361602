@import 'styles/theme.scss';

.selected-items-section { 
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 24px 10px;
  & > div.selected-items-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
  }
  h3 {
    font-size: $medium-text-font-size;
    text-align: center;
  }

  .selected-item {
    position: relative;
    .remove-icon {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: 2;
      height: 24px;
      transition: 0.2s opacity;
    }

    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      border-radius: 4px;
      top: 6px;
      right: 6px;
      bottom: 6px;
      left: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background-color: #06044d;
      opacity: 0;
      transition: 0.2s opacity;
    }

    &:hover {
      .remove-icon {
        opacity: 1;
      }
      &::after {
        opacity: 0.5;
      }
    }
  }
}

@media (max-width:768px) {
  .library-modal {
    .library-modal-container {
      .nav-section {
        width: 100%;
      }
    }

    .selected-items-section {
      display: none;
    }
  }
}
