// ---------- common code for buttons ----------
.ant-btn {


  // ---------- small and medium buttons ----------
  &.button,
  &.small-btn,
  &.medium-btn,
  &.large-btn {
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset !important;
    box-shadow: unset !important;
    height: 24px;
    padding: 0 3px;
    font-weight: 500;


    .anticon+span {
      margin-left: 3px;
      padding-right: 6px;
    }

    >span {
      display: flex;
      align-items: center;
      height: 24px;
    }

    &.icon-only {
      gap: 0;

      .anticon {
        margin: 0;
      }
    }

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.6;
    }

    &:after {
      display: none;
    }

    &:hover {
      filter: brightness(1.1);
    }
  }

  // ---------- medium sized button ----------
  &.medium-btn {
    height: 32px;
    padding: 0 14px;

    .anticon+span {
      margin-left: 6px;
    }
  }

  // ---------- large sized button ----------
  &.button,
  &.large-btn {
    height: 42px;
    padding: 0 18px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    gap: 10px;

    &.icon-right {
      padding-right: 8px;
    }

    &.icon-only {
      padding: 0 14px;
    }
  }


  // ---------- colored button ----------
  &.ant-btn-primary,
  &.confirm,
  &.secondary-btn {
    background-color: $secondary-color !important;
    color: $white-color !important;

    & svg>* {
      fill: $white-color;
    }

    &:hover {
      color: $white-color !important;
    }
  }

  &.cancel,
  &.grey-btn {
    background-color: #F5F6FA !important;
    color: $primary-color-text !important;

    & svg>* {
      fill: $primary-color-text;
    }

    &:hover {
      filter: brightness(0.95);
    }
  }

  &.white-btn {
    background-color: $white-color !important;
    color: $primary-color-text !important;
  }

  &.transparent-btn {
    background-color: transparent !important;
    color: $primary-color-text !important;
  }

  &.blue {
    background-color: $section-color !important;
    color: $white-color !important;

    &:hover {
      filter: brightness(1.2);
    }
  }

  &.light-blue {
    background-color: $publish-background !important;
    color: $section-color !important;

    & svg>* {
      fill: $section-color;
    }

    &:hover {
      filter: brightness(0.95);
    }
  }

  &.grey-line-btn {
    background-color: transparent !important;
    border: 1px solid $border-color-base !important;
    color: $label-color-dark !important;
  }

  &.green-line-btn {
    background-color: transparent !important;
    border: 1px solid $completed-color !important;
    color: $completed-color !important;
  }

  &.icon-right {
    flex-direction: row-reverse;
  }
}


/*------------ show / hide as per device width ---------------*/
@include sm() {
  // display something only in screen > sm, i.e > 576
  .above-sm {
    display: none !important;
  }
}

@media (min-width: #{$screen-sm-min}) {
  // display something only in screen < sm, i.e > 576
  .below-sm {
    display: none !important;
  }
}


@include md() {
  .above-md {
    display: none !important;
  }
}

@media (min-width: #{$screen-md-min}) {
  .below-md {
    display: none !important;
  }
}


@include lg() {
  .above-lg {
    display: none !important;
  }
}

@media (min-width: #{$screen-lg-min}) {
  .below-lg {
    display: none !important;
  }
}
/*------------ END of show / hide as per device width ---------------*/



/*------------ hide 2nd month for date range picker in mobile ---------------*/
@media (max-width: 600px) {
  .single-month-picker {
    .ant-picker-panels {
      .ant-picker-panel:nth-child(2) {
        .ant-picker-date-panel {
          width: 0;

          .ant-picker-header {
            position: absolute;
            right: 30px;
          }
        }

        .ant-picker-header-prev-btn,
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-view,
        .ant-picker-body {
          display: none;
        }
      }
    }
  }
}
/*------------ END hide 2nd month for date range picker in mobile ---------------*/
