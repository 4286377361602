.input-number-container {
  display: flex;
  .increment-container {
    display: flex;
    flex-direction: column;
    span {
      padding: 2px;
      svg { 
        background-color: #ebecf3;
        border-radius: 3px;
        &:hover{
          background-color: #dbdce3;
        }
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}