@import 'styles/theme.scss';
.settings-menu-wrapper {
  @include custom-scrollbar;
  left: auto !important;
  right: 0 !important;
  top: 64px !important;
  overflow-y: auto;
  max-height: calc(100vh - 140px);
  z-index: 1007 !important;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  .menu {
    padding: 18px !important;
  }
  & > ul {
    border-bottom-left-radius: 8px;
  }

  .ant-picker-clear {
    width: 21px;
    height: 19px;
  }

  background: $white-color;
  box-shadow: 0px 0.625em 1.25em rgba(4, 3, 49, 0.2);

  .settings-label {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #4F4E7B;

    & > span:first-child {
      flex-grow: 1;
    }
  }

  .settings-menu {
    margin-top: 0px;
    background-color: #F5F6FA;
    border-radius: 8px !important;
    margin-bottom: 20px;
    border-right: none;
    box-shadow: none;
    &.last-child, &.redirect {
      margin-bottom: 0;
    }
    .form-item {
      display: flex;
      flex-direction: column;
      height: auto !important;
      &:last-child {
        margin-bottom: 20px;
      }
      &.ant-menu-item-selected {
        background-color: #F5F6FA !important;
      }
      &.signed-email {
        display: block;
      }
      .form-label {
        font-size: 14px;
        opacity: 1;
        font-weight: 400;
        color: #8f8fb9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;
      }
      .anticon {
        margin-right: 0;
      }
      .beta-tag {
        justify-content: center;
        padding: 4px 16px;
        min-width: 60px;
        width: 60px;
        height: 26px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        letter-spacing: .04em;
        background: rgb(252, 243, 236);
        color: rgb(233, 109, 40);
        &.margin-start {
          margin-left: 8px
        }
      }
    
      .blur-overlay {
        position: relative;
        transition: backdrop-filter 0.4s ease, background-color 0.4s ease;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(2px);
          -webkit-backdrop-filter: blur(2px);
          background-color: rgba(245, 246, 252, 0.6);
          transition: backdrop-filter 0.4s ease, background-color 0.4s ease;
          pointer-events: none;
          z-index: 1;
        }
        > * {
          pointer-events: none;
          z-index: 0;
        }
      }
    }

    &.buttons {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      top: auto;
      border-radius: 0 !important;
      border-top: 1px solid #F5F6FA;
      background-color: $white-color;
      width: 100%;
      margin-bottom: 0;
      border-right: 0;
      .buttons {
        padding: 10px 18px;
        height: auto;
        cursor: default;
        display: flex;
        justify-content: flex-end;
        &.ant-menu-item-selected {
          background-color: $white-color !important;
        }
      }
    }
    

    .ai-tool-toggle {
      display: flex;
      align-items: center;
      button {
        margin-right: 7px;
      }
      .check-label {
        font-size: 16px;
        color: #05034d;
        margin-bottom: 0 !important;
      }
      #show-footer.ant-switch {
        height: 21px;
        min-width: 35px;
      }
      .ant-switch-handle {
        top: 3px;
        width: 15px;
        height: 15px;
      }
      .ant-switch-checked {
        background-color: #2f2c88;
        .ant-switch-handle {
          left: calc(100% - 16px - 2px);
        }
      }
    }

    .email-error {
      border-color: $error-color;
    }

    .contact-person-email-error, .redirect-to-error {
      border-color: $error-color;
    }

    .optional-text {
      color: rgba(175, 177, 186, 0.60);
    }

    .call-error {
      border-color: $error-color;
    }

    .whatsapp-error {
      border-color: $error-color;
    }

    .signature-email-error {
      border-color: $error-color !important;
    }

    .ant-dropdown-menu-item {
      background: none !important;
    }

    .date-picker {
      border: 1px solid $tab-border !important;
      border-radius: 4px;
      background-color: white !important;
      &:hover {
        border-color: $tab-border !important;
      }
      input {
        border: 0;
      }
    }

    .ant-select {
      width: 100%;
      &:focus {
        box-shadow: none;
      }
    }

    input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid $tab-border;
      border-radius: 4px;
      color: $primary-color-text;
      &:focus,
      &:hover {
        box-shadow: none;
        border-color: none;
      }
    }

    input::placeholder,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector::placeholder {
      color: rgba(175, 177, 186, 0.6)
    }

    .signature-emails-col {
      display: flex;
      flex-direction: column;
      .ant-input-affix-wrapper {
        border: 1px solid $tab-border;
        border-radius: 4px;
        margin: 0.5em 0;

        &:first-child {
          margin-top: 0;
        }

        &:focus,
        &:hover,
        &:active,
        &:focus-visible,
        &.ant-input-affix-wrapper-focused {
          box-shadow: none;
          border-color: none;
        }

        .ant-input {
          border: none;
        }

        .ant-input-suffix {
          .anticon {
            margin-right: 0;
          }
          svg rect:first-child {
            fill: white;
          }
        }
      }
    }

    .buttons {
      .ant-btn:nth-child(3) {
        margin-left: 15px;
      }
      .anticon{
        margin-right: 0;
        svg{
          height: 15px;
          width: 15px;
        }
      }
    }
  }
}

.settings-button {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 0px 10px;
  box-shadow: none !important;
  &.left {
    margin-left: 9px !important;
  }
  &.right {
    margin-right: 9px !important;
  }
  & > span {
    vertical-align: middle;
  }
  border: none !important;
  background-color: transparent;
  &.ant-dropdown-open,
  &:hover {
    background-color: #f5f4f9 !important;
    border-radius: 4px !important;
    color: $heading-color !important;
  }
}

.settings-item-name {
  padding-left: 0.5em;
  font-style: normal;
  font-weight: normal;
  font-size: $label-font-size;
  line-height: 140%;
  color: $primary-color-text;
}
