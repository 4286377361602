@import 'styles/theme.scss';


.tag-popover {
    --max-height: 350px;
    left: 396px !important;

    @media (max-width: 661.99px) {
        left: calc(50% - 134px)!important; 
    }

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        min-height: calc(var(--max-height) + 85px);
        background-color: unset;
        box-shadow: unset;
        position: relative;
        z-index: 2;
    }

    .closeable-mask {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

.tags-card {
    width: 300px;
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow: hidden;

    .loader {
        position: absolute;
        top: 0;
        height: 100%;
        background-color: $white-color;
        z-index: 1;
        width: 100%;
    }

    .tag-header {
        display: flex;
        justify-content: space-between;
        padding: 12px 12px 10px 12px;


        .tag-title {
            font-weight: 600;
            color: $primary-color-text;
        }

        .ant-card-head-title {
            color: $primary-color-dark;
        }

        .action-buttons {
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            .ant-btn {
                min-width: 35px !important;

                &.active {
                    background-color: $primary-color-01-light !important;
                }

                &.active {
                    svg rect {
                        fill: $primary-color-01-light;
                    }
                }

                &:disabled {
                    cursor: not-allowed;

                    svg path {
                        opacity: 0.4;
                    }
                }
            }
        }
    }


    .search-tags {
        &:not(.ant-input-affix-wrapper-focused) .ant-input-prefix {
            opacity: 0.4;
        }
    }



    .create-new-tag {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 130px 0 0;

        .add-new-tag-text {
            color: $primary-color-text;
            width: 185px;
            margin-top: -30px;
        }

        .create-new-tag-button {
            margin-bottom: 20px;

            .anticon,
            .anticon svg {
                height: 20px;
                width: 20px;
            }
        }
    }

    .create-tags-input {
        padding: 0 15px;

        input {
            border-radius: 4px;

            &:focus {
                border-color: $primary-color-text;
                box-shadow: unset;
            }
        }
    }

    .save-tag-footer-section {
        padding: 15px;

        .tags-error {
            font-size: 12px;
            color: $error-color;
        }
    }


    .tags-divider {
        margin: 0;
    }



    .ant-collapse {
        margin-top: 0 !important;

        .ant-collapse-arrow {
            top: 12px !important;
        }

        .ant-collapse-header {
            background-color: $tab-background !important;
            color: $primary-color-text !important;
            padding: 10px 15px 10px 40px !important;
        }

        .ant-collapse-content-box {
            padding: 0 !important;
        }
    }


    .ant-input-affix-wrapper-focused {
        box-shadow: none !important;
    }
}

.tag-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}


.edit-tag-list {
    padding: 8px 0 8px 16px;
    display: flex;

    &.dnd-wrapper {
        padding-left: 0;
    }

    .tag-list-item {
        flex: 1;
    }

    .tag-list-input {
        display: flex;
        align-items: center;
        flex: 1;
    }

    &:not(.drag-overlay):hover {
        background-color: $light-color;
    }

    .edit-tag-color-circle {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        margin-left: 7px;
    }

    .edit-tag-name {
        padding-left: 0;

        &:focus {
            border: none;
            box-shadow: none !important;
        }

        .ant-input {
            font-size: smaller;
        }
    }



    .name {
        flex: 1;
        display: flex;
        border: 1px $tab-border solid;
        border-radius: 4px;
        background-color: #ffffff;
        align-items: center;

        .ant-divider {
            border-left: 1px $tab-border solid;
            height: 30px;
            transform: translateY(0.5px);
        }

        &.error {
            border-color: $form-error-color;

            .ant-divider {
                border-color: $form-error-color;
            }
        }
    }

    .error-mgs {
        color: $form-error-color;
        font-size: 10px;
    }

    input {
        background-color: transparent;
        flex: 1;
    }

    .delete-icon {
        margin: 0 2px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        width: 30px;
        justify-content: center;
        height: 30px;

        &:hover,
        &.ant-popover-open {
            background: $secondary-color-light !important;
        }
    }
}


.create-tag-color-list {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    margin-top: 10px;

    &.edit-popover {
        margin: 0;
    }
}

.color-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.color-circle .check-mark {
    display: flex;
}

.select-tag-color {
    font-size: 12px;
    color: $label-color-light;
}

.edit-popover {
    padding: 12px 16px !important;
}

.delete-tag {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .delete-tag-icon {
        padding: 11px 20px 20px;

        svg {
            width: 50px !important;
            height: auto;
        }
    }

    .close-delete-tag {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .delete-tag-text {
        color: $primary-color-text;
        font-weight: 600;
        margin-bottom: 10px;
        max-width: 180px;
        word-break: break-word;
    }
}


.proposal-tags {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 4px;

    .count-tag {
        min-width: 30px;
    }

    .ant-btn {
        font-size: 0.8em;
        height: 24px;
        border: 1px solid transparent;
        padding: 0px 6px;
        border-radius: 4px;

        &:after {
            display: none;
        }

        &:hover,
        &:focus {
            background: inherit;
            color: inherit;
            border: 1px solid transparent;
            box-shadow: inherit;
        }
    }

    .add-tags {
        background-color: $light-color;
        color: $primary-color-text;
        font-weight: 500;

        &:hover,
        &:focus {
            background: $primary-color-01-light
        }
    }
}



.tags-card {
    .tags-card-body {
        overflow: auto;
        @include custom-scrollbar;
        min-height: 300px;
        max-height: var(--max-height);
    }

    .tags-body-wrapper {
        padding: 15px;
    }

    .tag-text-button {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        span {
            max-width: 15em;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap !important;
        }
    }

    .tags-list {
        padding: 10px 0;

        &.dragable {
            .tag-checkbox {
                padding: 6px 15px 6px 0;
            }

            .tag-li {
                flex: 1;
            }
        }

        .drag-handle {
            width: 16px;
            height: 26px;
            padding: 2.5px 4px;
            cursor: grab;
            margin-top: 4px;

            .anticon {
                overflow: hidden;
                height: 12px;
                width: 100%;
            }
        }

        .dnd-wrapper {
            display: flex;
        }
    }

    .tag-checkbox {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 6px 15px 6px 16px;

        .ant-checkbox {
            transform: translateY(1px);

            &::after {
                border: none;
            }
        }

        span:nth-child(2) {
            display: flex;
        }
    }
}