@import "styles/theme.scss";
.integration {
  .integration-header {
    margin-bottom: 30px;
  }

  
  @include rwd-min(1200) {
    padding: 20px 50px;

    .integration-main-content {
      background-color: $white-color;
      box-shadow: 0px 2px 4px rgba(3, 20, 63, 0.1);
      padding: 3em;
      border-radius: 4px;
    }
  }
}

