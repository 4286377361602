@import "styles/theme.scss";

.add-user-modal.simple-info-modal {
  width: 62em !important;

  .left-container{
    display: flex;
    flex-direction: column;
    min-height: 80vh;
  }
  .ant-modal-content {
    height: auto;
    .ant-modal-body {
      padding: 0;
      > .ant-row {
        .add-user-form {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          > .ant-col {
            &:first-child {
              border-right: 1px solid $tab-border;
            }

            .padding-left {
              padding-left: 2em;
            }

            .add-user-modal-header {
              padding: 2em;
              &.add-user-modal-shadow {
                box-shadow: $box-shadow;
              }

              .divider {
                margin: 1em 0em;
              }
              .add-user-modal-admin {
                .ant-row {
                  &:first-child {
                    color: $label-color-light;
                  }
                  &:last-child {
                    color: $primary-color-text;
                  }
                  span {
                    line-height: 140%;
                  }
                  .ant-tag {
                    background-color: $section-background;
                    border-color: $section-background;
                    color: $primary-color-08-opacity;
                    border-radius: 40px;
                    margin-left: 1em;
                    margin-top: -0.3em;
                    padding: 0.34em 1.2em;
                  }
                }
              }
            }

            .form-container {
              position: absolute;
              height: calc(100% - 160px);
              width: 100%;
              bottom: 0;
              display: flex;
              flex-direction: column;

              .form-inputs {
                height: calc(100% - 80px);
                width: 100%;
                position: absolute;
                @include custom-scrollbar;
                overflow-y: auto;
                padding: 20px 0;
              }
              .user-group-container {
                position: relative;
                .user-group {
                  display: flex;
                  width: 100%;
                  padding-right: 15px;

                  .ant-row {
                    flex-direction: column;
                    flex-wrap : nowrap!important;
                    text-align: left;
                  }
                  .ant-form-item-label{
                    max-height: 35px;
                  }

                  .remove-item-container {
                    width: 4em;
                  }

                  .user-info-container {
                    flex-direction: row;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .ant-form-item {
                      &:first-child {
                        width: 60%;
                      }
                      &:last-child {
                        width: 40%;
                        padding-left: 15px;
                      }
                      input {
                        color: $primary-color-text;
                        margin-right: 1em;
                      }
                      .ant-select-selector {
                        border-radius: 4px;
                      }

                      &.user-group-item-email {
                        .ant-form-item-required {
                          position: relative;
                          &::before {
                            left: 45px;
                            position: absolute;
                          }
                        }
                      }
                      &.user-group-item-role {
                        .ant-form-item-required {
                          &::before {
                            left: 37px;
                            position: absolute;
                          }
                        }
                      }
                    }
                  }

                  .user-info-container-unverified {
                    .ant-form-item {
                      .ant-form-item-control-input-content {
                        .ant-select-selector,
                        input {
                          border: 1px solid red;
                        }
                      }
                    }
                  }
                }
              }
              .add-user {
                background: $tab-background;
                color: $primary-color;
                border: none;
                width: 100%;
                height: 44px;
                font-weight: 600;
                font-size: $small-font-size;
                &:disabled {
                  opacity: 0.8;
                }
              }

              .remove-icon {
                margin: 0.3em 0.4em 0em 0.4em;
              }

              .add-user-divider {
                margin-bottom: 1rem;
                margin-top: 1rem;
                background: $tab-border;
                width: 100%;
                height: 1px;
                position: relative;
                margin-left: auto;
              }
              // }
            }

            .actions {
              display: flex;
              position: absolute;
              bottom: 0;
              width: 100%;
              padding: 20px;
              button.submit {
                margin-right: 12px;
                background: $secondary-color;
                color: $white-color;
                box-shadow: $light-box-shadow;
                border: 2px solid $secondary-color;

                .ant-btn-loading-icon {
                  position: absolute;
                  margin-left: -1.5em;
                  .anticon-loading {
                    padding: 0;
                    margin-right: 8px;
                  }
                }

                &:hover {
                  background: $secondary-color-hover;
                  border: 2px solid $secondary-color-hover;
                }
              }

              button.cancel {
                margin-left: 12px;
                background: $tab-background;
                color: $primary-color;
              }

              button {
                border: none;
                width: 100%;
                height: 44px;
                font-weight: 600;
                font-size: $small-font-size;
                text-transform: uppercase;
                box-sizing: border-box;
                border-radius: 4px;
              }
            }

            &.add-user-modal-plan-summary {
              padding-bottom: 100px;
              .logo {
                padding: 20px;
                svg {
                  width: 80%;
                  max-width: 200px;
                  border-radius: 50%;
                  transition: 0.4s;
                }
              }
              &.new-user{
                .logo svg{
                  width: 66%;
                }
              }
              .title {
                justify-content: center;
              }

              .summary-content-container {
                margin-bottom: 20px;
                .summary-content-content {
                  padding-top: 0.5em;

                  .summary-content-title{
                    span {
                      display: flex;
                      justify-content: right;
                    }
                  }

                  .summary-content-title,
                  .summary-content-credits {
                    text-align: right;
                    padding: 0 1em;
                    font-size: $sub-font-size;
                    line-height: 150%;
                    letter-spacing: 0.04em;
                    color: $primary-color-text;

                    .summary-content-title-tooltip {
                      color: $primary-color-text;
                    }

                    .summary-content-title-icon {
                      margin: auto 0.3em;
                      cursor: pointer;
                      svg {
                        height: 16px;
                        width: 16px;
                        border: 1px solid $svg-fill-border-extra-light;
                        border-radius: 50px;

                        circle {
                          fill: $white-color;
                        }

                        path {
                          fill: $svg-fill-border-light;
                        }
                      }
                    }

                    .summary-content-title-coupon-text {
                      margin-right: 2em;
                      text-decoration: underline;
                      color: $secondary-color;
                      cursor: pointer;
                    }

                    .summary-content-title-coupon-text-applied {
                      margin-right: 2em;
                    }
                  }

                  .summary-content-value,
                  .summary-content-value-total {
                    text-align: left;
                    padding: 0 2em;
                    display: flex;
                    span {
                      font-size: $label-font-size;
                      font-weight: 600;
                      line-height: 150%;
                      letter-spacing: 0.04em;
                      color: $primary-color-text;
                      &::first-letter {
                        font-size: $sub-font-size;
                        display: none;
                      }
                    }

                    &--divider {
                      @extend .summary-content-value;
                      padding: 0 1em;
                    }

                    .ant-divider {
                      min-width: 40%;
                      width: 40%;
                      border-top: 1px dashed $primary-color-text;
                      margin: 0;
                      display: block;
                    }
                  }

                  .summary-content-value-total {
                    &--coupon {
                      @extend .summary-content-value-total;
                      padding: 0 1.5em;

                      .summary-content-value-unit {
                        font-size: $label-font-size !important;
                      }

                      .summary-content-title-icon {
                        padding: 2px;
                        cursor: pointer;

                        &--close {
                          @extend .summary-content-title-icon;
                          margin-left: 5px;
                          
                          svg {
                            rect {
                              &:first-child {
                                fill: $white-color;
                              }
                              &:not(:first-child) {
                                fill: $svg-fill-border-light;
                              }
                            }
                          }
                        }

                        svg {
                          height: 16px;
                          width: 16px;
                          border: 1px solid $svg-fill-border-extra-light;
                          border-radius: 50px;

                          circle {
                            fill: #ffffff;
                          }

                          path {
                            fill: $svg-fill-border-light;
                          }
                        }
                      }
                    }

                    .summary-content-value-unit {
                      font-weight: 600;
                      font-size: $medium-text-font-size;
                      line-height: 140%;
                      color: $secondary-color;
                    }

                    .summary-content-value-duration {
                      font-size: $sub-font-size;
                      line-height: 28px;
                      font-weight: 400;
                      text-align: center;
                      color: $primary-color-text;
                    }
                  }

                  .summary-content-credits {
                    text-align: center;
                    font-size: 12px;
                    padding: 0 4em;
                    padding-top: 0.5em;
                  }
                }
              }

              .summary-info {
                margin: 0em 2em 0em;
                padding: 0.2em;
                text-align: initial;
                border: 1px solid $tab-border;
                box-sizing: border-box;
                border-radius: 4px;
                bottom: 6.5em;
                line-height: 140%;
                .ant-col {
                  margin: 0.2em;
                  span {
                    color: $primary-color-text;
                    .add-user-box {
                      padding: 0em 2em 0em 2em;
                      margin: 0.5em 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.plans-type {
  justify-content: center;
  & > * {
    margin: 0 0.2em;
  }

  .ant-switch-checked {
    background-color: $primary-color-text;
  }

  span {
    color: $primary-color-text;
    line-height: 21px;
    font-size: 14px;
  }

  span.active {
    font-weight: 600;
  }

  & > div {
    line-height: 18px;
    padding: 0 0.5em;
  }

  span:last-child {
    font-size: 12px;
    font-weight: 400;
    padding: 0 0.5em;
  }
}

.single-user-plan-summary {
  .single-user-plan-summary-heading {
    font-weight: 600;
    margin: 1em 0 1em 0.5em;
  }
  .single-user-plan-summary-list {
    p {
      font-weight: 300;
    }
    .feature-list-check-icon {
      margin: 0 1em 0 0;
      padding-left: 0.5em !important;
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .feature-list-info-icon {
      margin: 0 0 0 0.2em;
      svg {
        height: 16px;
        width: 16px;
        border: 1px solid $svg-fill-border-extra-light;
        border-radius: 50px;

        circle {
          fill: $white-color;
        }

        path {
          fill: $svg-fill-border-light;
        }
      }
    }
  }
}

.summary-content-add-coupon-popover {
  .ant-popover-inner-content {
    width: 18em;
    min-height: 4em;

    .summary-content-add-coupon-input-container {
      padding: 11px;
      border-radius: 5px;

      .ant-input-group {
        display: flex;
        padding: 4px;
        border: 1px solid $tab-border;
        border-radius: 4px;
        height: 2.5em;
        .ant-input {
          border: none;
          text-transform: uppercase;
          &:focus {
            border: none;
            box-shadow: none;
          }
        }
        .ant-btn.summary-content-add-coupon-input-button {
          border: 0;
          background-color: $secondary-color;
          color: white;
          border-radius: 5px;
          font-weight: 600;
          height: 1.8em;
          padding: 0px 15px;

          &--disabled {
            @extend .ant-btn.summary-content-add-coupon-input-button;
            background-color: $side-bar-border-color;
          }
          span {
            display: flex;
            font-size: $sub-font-size;
          }
        }
      }

      .error-field {
        margin-bottom: -1em;
        font-size: $sub-font-size;
        text-align: center;
        color: red;
        padding: 0.2em;
      }
    }
  }
}
