@import 'styles/theme.scss';

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item {
  margin-top: 12px !important;
  padding: 8px ;
  min-height: 80px;
  background: #ffffff;
  box-shadow: $box-shadow;
  border-radius: 4px;
  cursor: pointer;
  transition-duration: 0.2s;
  justify-content: space-between;
  position: relative;
  &:hover {
    box-shadow: 0 10px 20px 0 rgba(168, 182, 191, 0.6);
    transform: translateY(-1px);
    transition-delay: 0.2s;
  }
  --name-gap: 0px;
  &.with-avatar {
    --name-gap: 56px;
  }

  .ant-avatar {
    margin: auto 16px auto 0;
  }

  .value {
    font-weight: normal;
    font-size: 16px;
    color: $primary-color-text;
    display: flex;
    align-items: center;
    padding-right: 10px !important;

    .proposal-name-wrapper {
      --proposal-name-gap: 10px;
      display: flex;
      align-items: center;
      max-width: calc(100% - var(--proposal-name-gap));
      &.edit-visible {
        --proposal-name-gap: 30px;
      }
    }
    .avatar-wrapper {
      width: 56px;
    }
    .name-wrapper {
      width: calc(100% - var(--name-gap));
    }
    .ant-row {
      padding-left: 5px;
    }

    .edit-icon {
      width: 32px;
      display: none;
      margin: 0;
    }


    .proposal-item-title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;

      .title{
        flex: 1;
        @extend .truncate;
        &.rtl-text {
          direction: rtl;
          text-align: left;
        }
      }

      &:hover{
        .edit-icon {
          display: flex;
        }
      }
    }


    &.value-prospect-name {
      &:hover{
        .edit-icon {
          display: flex;
        }
      }
    }

    
    

    .edit-icon,
    .open-logs-icon {
      margin-left: 4px;
      padding: 4px 4px;
      border-radius: 50%;
      &:hover,
      &.proposal-item-name-active {
        background: $light-color;
      }
    }
  }
  .status {
    padding: 4px 0;
    min-width: 98px;
    height: 26px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    width: 95px;
    text-transform: capitalize;
  }

  .action-column {
    text-align: center;
    a {
      display: block;
    }
    a {
      background-position: center;
      transition: background 0.8s;
      border-radius: 5px;
    }
  }

  @media (max-width: 991.9px) {
    flex-flow : column!important;
    flex-direction: column;
    align-items : flex-start!important;
    gap: 8px;
    &.with-avatar{
      --name-gap : 30px;
    }
    .value{
      font-size: 12px;
      max-width:unset;
      position: unset;
    }

    .action-column{
      position: absolute;
    }
    .value-proposal-title{
      font-size: 14px;
      width:calc(100% - 25px);
      top: 10px;
      .title{
        display: -webkit-box;
        line-clamp: 2; 
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        white-space : unset !important;
      }
      .proposal-name-wrapper{
        --proposal-name-gap : 0;
        width: 100%;
      }
      .avatar-wrapper{
        width: 30px;
        .ant-avatar{
          transform: scale(0.6);
          transform-origin: left;
        }
      }
    }
  
    .value-prospect-name {
      width:calc(100% - 50px);
      &:before {
        content: 'Prospect ';
        padding-right: 10px;
        opacity: 0.6;
      }
    }
    .value-quote{
      &:before {
        content: 'Quote';
        padding-right: 26px;
        opacity: 0.6;
      }
    }
    .value-status{
      bottom: 10px;
      right: 0;
      position: absolute;
    }
    .action-column{
      top: 10px;
      right: -8px;
    }
  
    
    .divider-col{
      width:100%;
    }
    .ant-divider-horizontal{
      margin: 0;
    }

    &.with-avatar .value-prospect-name,
      &.with-avatar .value-quote{
      left: calc(var(--name-gap) + 10px);
    }
  }

  @media (min-width: 576px) {
    .tags-column{
      width: calc(100% - 120px);
    }
  }

  @media (min-width:991.9px) {
    padding-left:15px!important;
  }
  @media (min-width:992px) and (max-width: 1200px) {
    .value-client{
      margin-bottom: 30px;
    }
    .value-quote{
      position: absolute;
      left: 47.5%;
      top: 45px;
      &:before {
        content: 'Quote';
        padding-right: 26px;
        opacity: 0.6;
      }
    }
  }
}

.loader-container {
  .item {
    min-height: 80px;
  }
  .value {
    min-width: 150px;
    &::before {
      display: none;
    }
  }
  .value-proposal-title {
    @include lg {
      top: 10px;
    }
    .skelton {
      height: 15px;
    }
  }
}

.proposal-actions {
  border-radius: 4px;
  .ant-dropdown-menu-item {
    padding: 9px 20px;
    color: $primary-color-text;
    &.ant-dropdown-menu-item-disabled {
      opacity: 0.4;
    }
  }
  .ant-dropdown-menu-submenu {
    padding: 6px 8px;
    color: $primary-color-text;
    .ant-dropdown-menu-submenu-title {
      color: $primary-color-text;
    }
    &.ant-dropdown-menu-item-disabled {
      opacity: 0.4;
    }
  }
  .delete-text {
    color: $error-color !important;
  }
  .restore-text {
    color: $success-color !important;
  }

  .approved-declined {
    border-top: 1px solid $tab-border;
    padding-top: 7px;
    .ant-col {
      padding: 5px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: $input-disabled-color;
      }
    }
    .ant-space-item {
      display: flex;
      align-items: center;
    }
    &:hover {
      background: transparent;
    }
  }
}

.edit-container {
  padding: 1em;
  .ant-input,
  .ant-select,
  .ant-select-single {
    border: 1px solid $primary-color-light;
    width: 340px;
    border-radius: 4px;
    .ant-select-selection-item {
      color: $primary-color-text;
    }
    .ant-select-arrow .anticon:not(.ant-select-suffix) {
      pointer-events: none;
    }
  }

  .error-field {
    color: red;
    text-align: center;
  }

  .button-wrapper {
    margin-top: 2em;
    .ant-btn {
      width: 88px;
      height: 32px;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      color: $primary-color-text;
      line-height: 140%;
      background: $light-color;
      border: 2px solid $light-color;
      &:hover {
        opacity: 0.8;
        background: $light-color;
        border-color: $light-color;
      }
    }
  }
}

.proposal-item-menu {
  width: 35px;
  height: 35px;
  padding: 0px;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  &:hover {
    background: $light-color;
  }
}

.proposal-item-view-info-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      border-color: black !important;
    }
    .ant-popover-inner {
      padding: 12px;
      background-color: black;
      border-radius: 4px;
      width: 250px;
      margin: 0 auto;
      .ant-popover-inner-content {
        color: white;
        text-align: center;
        font-size: 12px;
        hr {
          border: 0.5px solid rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.proposal-number-popover.proposal-item-view-info-popover {
  .ant-popover-content {
    .ant-popover-inner {
      width: 100% !important;
    }
  }
}

.client-name-dropdown-input {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
  input {
    flex: auto;
    color: $primary-color-text;
  }
}

.subheading {
  font-size: 12px;
  display: contents;
  font-weight: 300;
  color: $label-color-light;
}

.ant-dropdown-menu.ant-dropdown-menu-sub.ant-dropdown-menu-vertical {
  padding: 0px 0px;
}
.ant-dropdown-menu-item {
  span {
    &:first-child {
      vertical-align: text-bottom !important;
    }
  }
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled {
  .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
}

.sub-header-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;

  .subproposal {
    font-size: 0.9em;
    color: $light-link-border-color;
    min-width: 38px;
  }

  .proposal-tags-wrapper {
    overflow: hidden;
    padding: 4px 4px;
    border-radius: 6px;
    &.active,
    &.tags-available:hover {
      background: #5855C314;
    }
  }

  .seprator {
    height: 18px;
    width: 1px;
    background-color: $tab-border;
    margin: auto 6px;
  }

  @media (min-width: 992px) {
    .subproposal {
      min-width: 46px;
    }
  }
}
