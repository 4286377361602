@import "styles/theme.scss";



.accordion-item {
    border-radius: 4px;
    background: $white-color;
    box-shadow: 0px 2px 8px 0px rgba(3, 20, 63, 0.10);
    flex-shrink: 0;
    border-left: 4px solid $primary-color;
    align-items: center;
    margin-top: 8px;

    >div:first-child {
        border-bottom: 1px solid $light-color;
        padding: 28px;
    }


    .email-desc {
        color: #808080;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    }
    .email-title {
        gap: 20px;
        align-items: center;

        .ant-switch-checked {
            background-color: $primary-color;
        }

        >span {
            color: $primary-color;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .accordion-content {
        padding: 28px;
        width: 100%;
        max-width: 1300px;
        justify-content: space-between;
    }

    .reminder-section {
        gap: 8px;

        input {
            width: 65px;
            padding: 5px 12px;
            text-align: center;
            gap: 4px;
            border-radius: 2px;
            border: 1px solid $border-color-base;
            background: $white-color;
        }
    }

    .email-preview-section {
        flex-direction: 'column';
        gap: 8px;

        .email-content {
            border-radius: 0px 0px 24px 24px;
            width: 503px;
            margin-top: -5px;
        }

        .preview-action-button {
            max-width: 350px;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .ant-form-item {
        padding-bottom: 24px;

        .form-label {
            .label {
                opacity: 0.8;
                color: $label-color-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 19.6px */
                text-transform: capitalize;
            }
        }

        label {
            display: flex;
            width: 100%;

            .ant-row {
                width: 100%;
            }
        }

        input {
            display: flex;
            padding: 12px;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid $tab-border;
            background: $white-color;
            color: $primary-color-text;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
    }

    .ant-input-number {
        border: 0;
    }

    .test-mail-section {
        display: flex;
        gap: 7px;
        cursor: pointer;

        span {
            color: $primary-color;
            text-align: center;
            /* Small Title */
            font-size: 14px;
            font-weight: 600;
            line-height: 140%;
        }
    }
}

// wrapped buttons css in .emails-wrapper, else it affects other parts too
.emails-wrapper {
    .preview-button {
        display: flex;
        justify-content: center;
        align-items: center;

        :last-child {
            color: $link-color !important;
            text-align: center !important;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 600 !important;
            line-height: 140% !important;
            margin-left: 4px !important;
            display: flex;
            align-items: center;
        }
    }
}

.save-btn {
    border-radius: 4px !important;
    border: 2px solid $secondary-color !important;
    background: $secondary-color !important;
    box-shadow: 0px 10px 15px -4px rgba(241, 92, 60, 0.40) !important;
    color: $white-color !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 140% !important;
    padding: 11px 28px !important;
    height: auto !important;

    &:hover,
    &:focus {
      opacity: 0.85;
      box-shadow: $box-shadow-base;
      border: none; 
    }
}

.reset-btn>span {
    color: $primary-color !important;
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 140% !important;
}

.email-action-button,
.email-action-button:hover {
    display: inline-flex !important;
    padding: 6px 6px 6px 8px !important;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: none !important;
    flex-direction: row-reverse;
    border-radius: 2px !important;
    background: $light-color !important;
    height: auto !important;

    >span:last-child {
        color: $primary-color-dark;
        font-size: 14px;
        font-weight: 400;
    }

    >span {
        margin: 0px !important;
    }
}

.variable-dropdown,
.variable-dropdown:hover {
    display: inline-flex !important;
    padding: 4px 4px 3px 8px !important;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: none !important;
    flex-direction: row-reverse;
    border-radius: 2px !important;
    background: $light-color !important;
    height: auto !important;

    >span:last-child {
        color: $primary-color-dark;
        font-size: 12px;
        font-weight: 400;
    }

    >span {
        margin: 0px !important;
    }
}

.variable-dropdown-menu {
    padding: 0 0 16px 0 !important;

    li {
        color: $primary-color-text;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        padding: 16px 16px 0px 16px;
        /* 19.6px */
    }
}

.info-list {
    gap: 64px;

    .email-status-info {
        align-items: center;
        gap: 8px;

        .text {
            color: $primary-color-text;
            font-size: 12px;
            font-weight: 400;
            opacity: 0.8;
        }

        .count {
            color: $primary-color-text;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.customize-email-modal.accordion-item {
    padding: 0;
    border: 0;

    .page-title {
        font-size: 24px;
    }

    >div:first-child {
        padding: 0px;
    }

    .accordion-content {
        padding: 0;
        margin-top: 26px;
    }

    .ant-form-item {
        margin: 0;
    }
}

.variable-select-dropdown {
    width: 232px;
    max-height: 418px;
    min-height: 200px;
    border-radius: 4px;
    background: $white-color;
    box-shadow: 0px 5px 20px 0px rgba(4, 3, 49, 0.15);
    overflow-y: auto;

    &::before {
        top: 207px !important;
    }

    &::-webkit-scrollbar {
        width: 10px; // Scrollbar width
        height: 10px; // Scrollbar height for horizontal scrolling
    }

    &::-webkit-scrollbar-track {
        background-color: #f0f0f0; // Scrollbar track color
        border-radius: 5px; // Optional: rounded corners for the track
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888; // Scrollbar thumb color
        border-radius: 5px; // Optional: rounded corners for the thumb
        border: 2px solid #ccc; // Optional: thumb padding
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555; // Thumb color on hover
    }

    // For Firefox
    scrollbar-width: thin; // Options: auto, thin, none
    scrollbar-color: $border-color-tab;

    .menu {
        .title {
            cursor: pointer;
            padding: 8px 8px 8px 16px;
            justify-content: space-between;

            .title-text {
                color: $primary-color-dark;
                font-size: 11px;
                font-weight: 600;
                letter-spacing: 0.22px;
                text-transform: uppercase;
                opacity: 0.6;
            }
        }

        .variable-list {
            flex-direction: column;
            padding: 8px 16px 8px 16px;

            .variable {
                padding: 8px;
                flex-shrink: 0;
                min-height: auto;
                color: $primary-color-text;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;

                &:hover {
                    border-radius: 4px;
                    background: $light-color;
                }
            }
        }
    }
}