@import 'styles/theme.scss';

@mixin attr-x($attr, $attr-count: 10, $attr-steps: 10, $unit: '%') {
  $attr-list: null;
  @for $i from 1 through $attr-count {
    $attr-value: $attr-steps * $i;

    .#{$attr}-#{$attr-value} {
      @if $attr == 'intent-left' {
        margin-left: #{$attr-value}#{$unit};
      } @else if $attr == 'intent-right' {
        margin-right: #{$attr-value}#{$unit};
      } @else {
        #{$attr}: #{$attr-value}#{$unit};
      }
    }

    $attr-list: append($attr-list, unquote('.#{$attr}-#{$attr-value}'), comma);
  }
}

.publish-content {
  .line-height {
    &__1-0 {
      line-height: 28px;
    }
    &__1-2 {
      line-height: 33.6px;
    }
    &__1-5 {
      line-height: 42px;
    }
    &__2-0 {
      line-height: 56px;
    }
  }

  .proposal-headersection {
    padding: 50px !important;
  }

  .simple-section {
    .texblockbody {
      margin-bottom: 0px;
      table.texblocktotals {
        margin-bottom: 0px;
      }
      & > table {
        width: 100% !important;
        table-layout: unset;
      }
    }
  }

  .simple-section,
  .proposal-headersection {
    margin-bottom: 0px;
    padding: 50px 50px 0;
    border-bottom: 0px none #fff;
    background-color: #fff;
    transition: background 0.5s ease;
    position: relative;
    box-shadow: none !important;

    .header-by-container {
      .ant-col {
        z-index: 1;
      }
    }

    .simple-section-container {
      .simple-section-title-block {
        .simple-section-title,
        .simple-section-sub-title {
          margin-top: 0px;
          margin-right: 0px;
          margin-bottom: 0px;
          font-family: 'Sharpsansno1 semibold', sans-serif;
          font-size: 36px;
          word-break: break-word;
          line-height: 1.6;
          p {
            margin: 0px;
            word-break: break-word;
          }
        }

        .simple-section-sub-title {
          line-height: 20px !important;
          padding-top: 4px !important;
        }
      }

      .simple-section-content {
        padding-top: 8px;
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 28px;
        color: #4a4a4a;

        &-multiple {
          display: flex;
        }

        &.column {
          .ant-col{
            max-width: 100%;
            flex: unset;
          }
        }

        .ant-col {
          float: left;
        }
        p {
          text-align: left;
          margin-bottom: 0;
          overflow-wrap: break-word;
        }

        ul {
          padding: 0;
          margin-bottom: 0;
          counter-reset: ol0;

          li {
            position: relative;
            margin-left: 1.5em;
            white-space: inherit;

            &:before {
              position: absolute;
              text-align: left;
              left: 0;
              transform: translateX(-100%);
              min-width: 17px;
            }
            ul {
              counter-reset: ol1;

              > li {
                margin-left: 1.5em;
                white-space: inherit;
                &:before {
                  counter-increment: ol1;
                }

                ul {
                  counter-reset: ol2;
                  > li {
                    margin-left: 28px;
                    white-space: inherit;
                    &:before {
                      counter-increment: ol2;
                    }

                    ul {
                      counter-reset: ol3;
                      > li {
                        margin-left: 28px;
                        white-space: inherit;
                        &:before {
                          counter-increment: ol3;
                        }

                        ul {
                          counter-reset: ol4;
                          > li {
                            margin-left: 28px;
                            white-space: inherit;
                            &:before {
                              counter-increment: ol4;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          > li.RichEditor-unordered-list-item-hyphen {
            list-style-type: none;
            &:before {
              content: url('https://dr08qaykn9isr.cloudfront.net/images/Webp.net-resizeimage+(1).png');
              left: -10px !important;
            }
          }
          > li.RichEditor-unordered-list-item-check {
            list-style-type: none;
            &:before {
              content: url('https://dr08qaykn9isr.cloudfront.net/images/Webp.net-resizeimage.png');
              left: -10px !important;
            }
          }
        }

        ol {
          padding: 0;
          margin-bottom: 0;
          list-style-type: none;
          counter-reset: ol0;
          word-break: break-word;

          li {
            position: relative;
            white-space: inherit;
            &:before {
              position: absolute;
              text-align: left;
              left: 0;
              transform: translateX(-100%);
              min-width: 17px;
            }
          }

          > li {
            margin-left: 18px;
            padding-left: 10px;
            white-space: inherit;
            &:before {
              counter-increment: ol0;
              content: counter(ol0) '. ';
              word-break: normal;
            }

            ol {
              counter-reset: ol1;

              > li {
                margin-left: 40px;
                padding-left: 10px;
                white-space: inherit;
                &:before {
                  counter-increment: ol1;
                  content: counter(ol0) '.' counter(ol1) '. ';
                  word-break: normal;
                }

                ol,
                ul {
                  counter-reset: ol2;
                  > li {
                    margin-left: 30px;
                    white-space: inherit;
                    &:before {
                      counter-increment: ol2;
                      content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '. ';
                      word-break: normal;
                      min-width: 44px;
                    }

                    ol,
                    ul {
                      counter-reset: ol3;
                      > li {
                        margin-left: 38px;
                        white-space: inherit;
                        &:before {
                          counter-increment: ol3;
                          content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' counter(ol3)
                            '. ';
                          word-break: normal;
                          min-width: 56px;
                        }

                        ol,
                        ul {
                          counter-reset: ol4;
                          > li {
                            margin-left: 47px;
                            white-space: inherit;
                            &:before {
                              counter-increment: ol4;
                              content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.'
                                counter(ol3) '.' counter(ol4) '. ';
                              word-break: normal;
                              min-width: 80px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          li:before {
            white-space: nowrap;
          }

          > li.RichEditor-ordered-list-item-alpha {
            &:before {
              counter-increment: ol0;
              content: counter(ol0, upper-alpha) '. ';
            }

            ul {
              > li {
                margin-left: 2em;
                white-space: inherit;
                &:before {
                  display: none;
                }
                ul,
                ol {
                  > li {
                    margin-left: 3.2em;
                    white-space: inherit;
                    &:before {
                      display: none;
                    }
                    ul,
                    ol {
                      > li {
                        margin-left: 3.7em;
                        white-space: inherit;
                        &:before {
                          display: none;
                        }
                        ul,
                        ol {
                          > li {
                            margin-left: 4.5em;
                            white-space: inherit;
                            &:before {
                              display: none;
                            }
                            &:before {
                              left: -3.5em;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ol,
            ul {
              counter-reset: ol1;

              > li {
                margin-left: 40px;
                white-space: inherit;
                &:before {
                  counter-increment: ol1;
                  content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '. ';
                }

                ol,
                ul {
                  counter-reset: ol2;

                  > li {
                    margin-left: 42px;
                    white-space: inherit;
                    &:before {
                      counter-increment: ol2;
                      content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '.'
                        counter(ol2, upper-alpha) '. ';
                    }

                    ol,
                    ul {
                      counter-reset: ol3;
                      > li {
                        margin-left: 40px;
                        white-space: inherit;
                        &:before {
                          counter-increment: ol3;
                          content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '.'
                            counter(ol2, upper-alpha) '.' counter(ol3, upper-alpha) '. ';
                        }

                        ol,
                        ul {
                          counter-reset: ol4;
                          > li {
                            margin-left: 40px;
                            white-space: inherit;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          > li > ol {
            // for 2nd level
            > li {
              // alpha (A.B.)
              &.RichEditor-ordered-list-item-alpha:before {
                counter-increment: ol1 !important;
                content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '. ' !important;
              }
              &:before {
                min-width: 40px;
              }
            }

            > li > ol {
              // for 3rd level of alpha (A.B.C)
              > li.RichEditor-ordered-list-item-alpha {
                &:before {
                  counter-increment: ol2 !important;
                  content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '. '
                    counter(ol2, upper-alpha) '. ' !important;
                  min-width: 58px;
                }
              }

              > li > ol {
                // for 4th level of alpha (A.B.C.D)
                > li.RichEditor-ordered-list-item-alpha {
                  &:before {
                    counter-increment: ol3 !important;
                    content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '. '
                      counter(ol2, upper-alpha) '. ' counter(ol3, upper-alpha) '. ' !important;
                    min-width: 72px;
                  }
                }

                > li > ol {
                  // for 5th level of alpha (A.B.C.D)
                  > li.RichEditor-ordered-list-item-alpha {
                    &:before {
                      counter-increment: ol4 !important;
                      content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '. '
                        counter(ol2, upper-alpha) '. ' counter(ol3, upper-alpha) '. '
                        counter(ol4, upper-alpha) '. ' !important;
                      min-width: 84px;
                    }
                  }
                }
              }
            }
          }
        }
        img {
          width: 100%;
          max-width: 100%;
          object-fit: cover;
        }
      }

      &.column-view {
        .pricing-layout-check-box {
          position: absolute;
          @media (max-width: 480px) {
            position: relative !important;
            height: 20px;
            left: 0 !important;
          }
          .mobile-screen & {
            position: relative !important;
            height: 20px;
            left: 0 !important;
          }
        }
      }
    }

    &#overview,
    &#terms,
    &#whyme2,
    &#milestones2,
    &#pricing2,
    &#whatsnext {
      .simple-section-content {
        ol,
        ul {
          li {
            @media screen and (max-width: 479px) {
              font-size: unset;
            }
          }
        }
      }
    }

    .header-section-container,
    .simple-section-content {
      .editor-align-right {
        text-align: right !important;
      }

      .editor-align-left {
        text-align: left !important;
      }

      .editor-align-center {
        text-align: center !important;
      }

      .editor-align-justify {
        text-align: justify !important;
      }

      .editor-direction-ltr {
        direction: ltr !important;
      }

      .editor-direction-rtl {
        direction: rtl !important;
      }

      @include attr-x('intent-left', 26, 1, 'em');
      @include attr-x('intent-right', 26, 1, 'em');

      blockquote {
        padding: 10px 20px;
        border-left: 5px solid #e2e2e2;
        font-size: 18px;
        line-height: 22px;
        word-break: break-all;
      }

      figure {
        // text-align: center; // price module when copy pasted, text becomes center aligned
        &.image-figure {
          text-align: unset;
        }
        img {
          width: 100%;
        }
      }

      .html-content {
        overflow-wrap: break-word;
        max-width: 100%;
      }

      .media-component {
        display: block !important;

        .thumbnail {
          cursor: pointer;
        }

        .embed-wrap {
          display: block !important;
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
          max-width: 100%;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .fa-play {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            color: white;
            z-index: 10;
            font-size: 50px;
            width: 70px;
            height: 70px;
            padding-top: 10px;
            background: #333;
            opacity: 0.8;
            position: absolute;
          }
        }

        .img-wrp {
          display: block !important;
          position: relative;
          max-width: 100%;
        }

        .pdf-media-thumb {
          width: 100%;
        }

        .origlink {
          display: block !important;
          font-size: 13px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }

    .header-section-container {
      position: relative;
      z-index: 2;
      > .ant-col .ant-row {
        flex-flow: column;
      }
    }

    .resizable-container {
      padding: 20px 0;
    }

    &.proposalheader-custom {
      .header-section-container {
        .ant-col {
          &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > .proposalheaderwhite {
              direction: initial !important;
              text-align: right !important;
              font-size: 24px !important;
            }

            .ant-row {
              flex-flow: column;

              h1 {
                direction: initial !important;
                text-align: center !important;
              }

              .resizable-container {
                direction: inherit;
                padding: 20px 0;

                .title-under-line {
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }
  }

  .proposal-signaturesection {
    .signature-col,
    .signature-btn-col,
    .additional-signature-btn-col {
      min-height: 6em;
      margin-right: 10px;
      margin-left: 10px;
      font-size: $label-font-size;
    }
  }

  @include xs {
    .simple-section,
    .proposal-signaturesection {
      padding: 10px;
    }
    #section-content-container .proposal-headersection {
      padding: 10px !important;
      height: 100% !important;
      .header-section-container {
        height: auto !important;
        margin-bottom: 0 !important;
        .proposalheaderwhite {
          margin-bottom: 1em;
        }
        .logo-section-col {
          position: relative !important;
          margin-bottom: 1em;
        }
      }
      .header-by-container {
        position: relative;
        padding: 0;
      }
    }
  }

  .proposalblock-image {
    padding: 0px;
  }

  .image-publish-container {
    p {
      display: none;
    }

    figure {
      img {
        width: 100%;
      }
    }
  }

  iframe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    border: none;
    overflow: hidden;
  }

  &.language-rtl {
    .simple-section-container {
      @media (max-width: 480px) {
        .pricing-layout-check-box {
          right: 6px !important;
          float: none !important;
        }
      }
    }
  }

  .react-photo-album{
    .react-photo-album--photo{
      background-color: #fff;
    }
  }
}

.form-error-text {
  color: $form-error-color;
  float: right;
  font-size: 0.7em;
}

.language-rtl {
  .publish-content,
  &.preview-section {
    text-align: right;

    ul {
      li {
        margin-right: 1.5em;
        margin-left: 0em;
        &:before {
          position: absolute;
          text-align: right;
          left: unset;
          transform: translateX(100%);
          min-width: 17px;
        }
        ul li {
          list-style-type: circle;
          &.RichEditor-unordered-list-item-hyphen,
          &.RichEditor-unordered-list-item-check {
            list-style-type: none;
          }
          ul li {
            list-style-type: square;
            &.RichEditor-unordered-list-item-hyphen,
            &.RichEditor-unordered-list-item-check {
              list-style-type: none;
            }
          }
        }
      }
    }

    ol {
      li {
        padding-right: 2.5em;
        padding-left: 0;
        white-space: inherit;

        br {
          display: none;
        }

        &.RichEditor-unordered-list-item-hypen::before {
          counter-increment: ol0;
          content: url('https://dr08qaykn9isr.cloudfront.net/images/Webp.net-resizeimage+(1).png') !important;
        }

        &.RichEditor-unordered-list-item-check::before {
          counter-increment: ol0;
          content: url('https://dr08qaykn9isr.cloudfront.net/images/Webp.net-resizeimage.png') !important;
        }

        &::before {
          right: 0.2em;
          text-align: right;
        }
        ol li {
          &.RichEditor-ordered-list-item-alpha {
            padding-right: 2.5em;
            margin-left: 0;
          }
          padding-right: 3em;
          margin-left: 0;
          white-space: inherit;

          br {
            display: none;
          }

          ol li {
            &.RichEditor-ordered-list-item-alpha {
              padding-right: 3.2em;
              margin-left: 0;
              margin-right: -1.5em;
            }
            padding-right: 4.2em;
            margin-left: 0;
            margin-right: -1.5em;
            white-space: inherit;

            br {
              display: none;
            }
            ol li {
              &.RichEditor-ordered-list-item-alpha {
                padding-right: 4em;
                margin-left: 0;
                margin-right: -2em;
              }
              padding-right: 5.5em;
              margin-left: 0;
              margin-right: -2.5em;
              white-space: inherit;

              br {
                display: none;
              }
              ol li {
                &.RichEditor-ordered-list-item-alpha {
                  padding-right: 5em;
                  margin-left: 0;
                  margin-right: -3em;
                }
                padding-right: 6.5em;
                margin-left: 0;
                margin-right: -3.5em;
                white-space: inherit;

                br {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .texblockbody {
      ol,
      ul {
        text-align: right;
      }
      .super-li {
        padding-right: 1.5em;
        &::before {
          right: 0.2em;
          text-align: right;
        }
      }
      .pkg-name {
        text-align: right;
      }
    }

    table tbody tr {
      .name,
      .grey-table-data-cell {
        text-align: right;
      }
      .price {
        text-align: left;
        direction: initial;
      }
      .time {
        text-align: left;
      }
      &.timerow {
        td:nth-child(1) {
          flex: 0.7;
        }
        td:nth-child(2) {
          flex: 0.3;
        }
      }
    }
  }

  .proposal-headersection.proposalheader-custom {
    .header-section-container {
      .ant-col {
        &:last-child {
          > .proposalheaderwhite {
            text-align: left !important;
          }
        }
      }
    }
  }
}
.grey-table-row {
  border: 1px solid #dbdbdb;
  .grey-table-data-cell {
    vertical-align: top !important;
  }
}
