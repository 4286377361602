@import 'styles/theme.scss';

.trial-end-header {
  top: 0;
  right: 0;
  position: fixed !important;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px!important;
  background-color: $secondary-color-light !important;
  height: 56px;

  @include rwd-min(576) {
    width: calc(100% - 80px);
  }
  @include rwd-min(1200) {
    padding: 0 50px !important;
  }

  .ant-page-header-content {
    padding-top: 0;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    color: $primary-color-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    p {
      margin: 0;
    }

    button {
      background: $secondary-color;
      border: 2px solid $secondary-color;
      box-sizing: border-box;
      box-shadow: $light-box-shadow;
      border-radius: 4px;

      span {
        color: white;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
      }
    }
  }
}