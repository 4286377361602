@import 'styles/theme.scss';

.dashboard-header {
  background-color: $border-color;
  z-index: 1;
  top: 0;
  width: 100%;
  pointer-events: none;
  position: sticky;
  &:before{
    content: '';
    background-color: $border-color;
    position: absolute;
    width: calc(100% + 60px);
    height: calc(100% + 30px);
    left: -30px;
    top: -30px;
    z-index: 0;
  }

  .prosper-icon-button {
    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &.shrink {
    @include lg {
      .page-header {
        .prosper-icon-button{
          min-width: 80px;
        }
        .prosper-button-add-icon{
          display: none;
        }
        .filter-btn{
            height: 44px;
            box-shadow: unset;
            border: unset;
            background: transparent;
            &:after{
              display: none;
            }
            &:hover,
            &:focus{
              border-color: unset;
            }

            .anticon{
              position: absolute;
              left: calc(50% - 15px);
              top: calc(50% - 15px);
              margin: 0;
              justify-content: center;
              align-items: center;
              display: flex;
              background: transparent!important;
              height:30px;
              width:30px;
            }

            svg{
              height:30px!important;
              width:30px!important;
              display: block;
            }
        }
      }
    }

    a,
    button,
    span,
    input {
      pointer-events: all;
    }

    .ant-picker-range {
      .ant-picker-clear {
        right: -7px !important;
        background: transparent !important;
      }
    }

    .item-filter-menu > div:nth-child(n + 2) {
      overflow: hidden;
      transition: 0.2s;
      opacity: 1;
      max-height: 65px;
    }

    .dashboard-list-header,
    .item-filter-menu {
      transition: margin 500ms;
      position: relative;
      z-index: 1;
    }

    &.hide-items {
      .item-filter-menu {
        margin-top: 0.5em;

        & > div:nth-child(n + 2) {
          max-height: 0;
          opacity: 0;
          padding: 0!important;
        }
      }
    }
  }
}

.handle-user-lock {
  .dashboard-header {
    &.shrink {
      .secondary-btn {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}