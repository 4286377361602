@import "styles/theme.scss";

.dashboard-list-header {
  padding: 8px;
  padding-left: 14px;
  margin-top: 1em;
  background: $background-color;
  border-radius: 4px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  justify-content: space-between;

  .header-value {
    font-weight: normal;
    font-size: 14px;
    color: $primary-color-dark;
    padding: 0px !important;

    &.watched{
      @include rwd(992) {
          display: none;
      }
    }
    
    @include sm {
      font-size: 12px;
    }
  }

  @include lg {
    display: none!important;
  }

  @media (min-width:992px) and (max-width: 1200px) {
    .value-quote{
     display: none;
    }
  }
}
