@import 'styles/theme.scss';

.header {
  position: fixed;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0em 1em 0em 1em !important;
  border-bottom: solid 1px #e8e8e8;
  background-color: #fff !important;
  box-shadow: $box-shadow;

  .logo, .logo-small {
    float: left;
    img {
      max-width: 163px;
    }
  }
  .logo-small {
    padding-left: 15px;
    padding-right: 30px;
    border-right: 1px solid $tab-border;
  }
  .right-menu {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    .activity-notification-icon {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      svg {
        width:28px;
      }
      &:hover {
        background: $light-color;
      }
    }

    .headway-notification {
      height: 30px;
      border-radius: 50%;
      padding: 6px;
      &:hover {
        background: $light-color;
      }
    }

    a {
      color: $primary-color;
    }

    li {
      &:last-child {
        padding-right: 0;
      }
      padding: 0 1em;
      position: relative;
    }

    .seprator {
      height: 24px;
      width: 1px;
      background-color: $tab-border;
    }

  }

  .headway-widget {
    position: relative;
    #HW_badge_cont {
      position: absolute;
      top: 8px;
    }
  }

  .HW_badge.HW_softHidden {
    background: none !important;
    opacity: 0;
    transform: scale(0.6);
  }

  @media screen and (min-width: 992px) {
    padding-right: 3.125em!important;

    .right-menu {
      li {
        padding: 0 2em;
      }
    }
  }
}

