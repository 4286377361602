@import "styles/theme.scss";

.rich-editor-gallery-edit {
  .ant-modal {
    width: 100% !important;
    max-width: 1400px;
    height: var(--height-100);
    margin-top: -100px;
  }

  .ant-modal-content {
    height: var(--height-100);
    display: flex;
    flex-direction: column;
  }

  .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      .title {
        margin-top: 0;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        color: $primary-color-text;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .ant-divider {
        background: $secondary-color;
        width: 32px;
        height: 1px;
        margin: 16px 0;
        min-width: 0;
      }
    }
  }

  .ant-modal-body {
    position: relative;
    padding: 0;
    margin-top: -32px;
    flex: 1;
    text-align: left;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    button {
      height: 44px;
    }
  }

  .ant-btn {
    border: unset;
    background-color: $light-color;
    color: $primary-color-dark;

    &:hover {
      box-shadow: unset;
      background-color: $transform-color;
      color: $primary-color-dark;
    }
  }

  .more-buttons {
    display: flex;
    gap: 10px;

    .plus-icon {
      display: none;
    }

    .buttons {
      display: flex;
    }
  }


  .gallery-drop-image-container {
    display: flex;
    justify-content: space-between;

    .dropzone {
      min-height: 50px;
      width: 563px;
      cursor: pointer;
      margin: 0 auto;
      padding-top: 0;
      transform: scale(1.03);
      border-radius: 2px;
      transform-origin: center;
      display: flex !important;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      display: block;
      margin-right: 0px;
      margin-left: 0px;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.58);
      opacity: 1;
      transition: opacity 350ms ease;
      text-align: center;

      p {
        color: white;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.select-box {
    .ant-modal-body {
      padding: 30px !important;
      height: auto !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: auto;
    }

    .ant-modal {
      max-width: 750px !important;
    }

    .select-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      p {
        margin: 0;
      }
    }

    .select-image-uploader {
      max-width: 500px;
    }
  }

  &.gallery-box {
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
    }

    .confirm {
      min-width: 150px
    }
  }

  .gallery-image-editor {
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  --gallery-width:100%;

  .gallery-album {
    --gallery-spacing: 5px;
    position: absolute;
    height: 100%;
    width: var(--gallery-width);
    overflow-y: auto;
    padding: 30px;
  }

  .photo-frame {
    position: relative;
    cursor: grab;

    &.overlay {
      cursor: grabbing;
    }

    &.active,
    .active img {
      opacity: 0.3;
    }

    .delete {
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;
      background-color: #fff;
      height: 30px;
      width: 30px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
    }

    &:hover .delete {
      opacity: 1;
    }
  }


  .gallery-settings {
    top: 0;
    right: 0;
    background: #ffffff;
    height: 100%;
    width: 80%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    transition: 0.2s;
    position: fixed;
    z-index: 100;

    &.in-active {
      transform: translateX(100%);
    }

    &.active {
      transform: translateX(0);
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    }

    .more-btns {
      display: flex;
      gap: 10px;
    }

    .settings-body {
      flex: 1;
      padding: 20px;
      overflow-y: auto;
    }
    .settings-footer{
      padding: 20px;
    }

    .shuffle {
      color: $primary-color-light;
      background-color: $transform-color;
    }

    .reset {
      color: $secondary-color;
      background-color: $secondary-color-light;
    }

    .layout-radio {
      width: 100%;
      margin-bottom: 25px;

      label {
        width: 33.33%;
        text-align: center;
        padding: 5px 0;
        height: unset;

        &.ant-radio-button-wrapper-checked {
          background-color: $section-color;
        }

        &:nth-child(1) {
          border-radius: 6px 0 0 6px;
        }

        &:nth-child(3) {
          border-radius: 0 6px 6px 0;
        }
      }
    }

    .group {
      border: 1px solid $light-color;
      border-radius: 6px;
      padding: 15px 10px 10px;
      margin-bottom: 20px;
      position: relative;

      .ant-col {
        padding-bottom: 0 !important;
      }

      .strong {
        font-weight: 600;
        display: block;
        position: absolute;
        top: -12px;
        background-color: #fff;
      }
    }

    .shadow {
      .tool {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &>div {
          width: calc(100% - 80px);
        }
      }
    }

    .ant-radio-button-wrapper {
      padding: 0;
    }
  }

  .react-photo-album {
    padding: 30px;
  }

  .open-setting-btn {
    position: absolute;
    top: -58px;
    right: 60px;
    border: unset;
  }

  .close {
    display: block;
    width: 100%;
    height: 44px;
    color: $primary-color-light;
  }

  @media screen and (max-width: 767px) {
    .more-buttons {
      .plus-icon {
        display: none;
        display: block;
        transform: rotate(45deg);
        transition: 0.2s;

        svg {
          height: 44px;
          width: 44px;

          &>* {
            fill: $primary-color-light;
          }
        }
      }

      .buttons {
        position: absolute;
        bottom: 70px;
        width: 220px;
        flex-direction: column;
        gap: 13px;
        background: #fff;
        padding: 12px;
        border-radius: 8px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        transition: 0.2s;
        opacity: 1;
        visibility: visible;
      }

      .ant-btn {
        width: 100%;
        margin: 0;
      }

      &.inactive {
        .buttons {
          bottom: 40px;
          opacity: 0;
          visibility: hidden;
        }

        .plus-icon {
          transform: rotate(0);
        }
      }
    }
  }

  @media screen and (min-width: 1100px) {
    --gallery-width: 700px;

    .ant-modal {
      height: 100%;
      margin: 0 auto;
      padding: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .settings-footer{
      display: none;
    }

    .ant-modal-content {
      width:100%;
      height: auto;
      min-height: 600px;
      max-height: calc(100vh - 60px);
    }

    &.gallery-box,
    &.library-modal{
      .ant-modal-content {
        flex: 1;
      }
    }

    .gallery-settings {
      position: absolute;
      min-width: calc(100% - var(--gallery-width));
      padding-top: 0;
      width: 200px;
      max-width: 1000px;

      &.in-active {
        transform: unset;
      }

      .shadow {
        .tools {
          display: flex;
          justify-content: space-between;
        }

        .tool {
          display: block;
          width: 40%;

          .ant-slider {
            width: calc(100% - 20px);
          }

        }
      }
    }

    .close,
    .open-setting-btn {
      display: none;
    }
  }

  @media screen and (min-width: 1300px) {
    --gallery-width: 860px;
  }
}


.photo-frame {
  img {
    box-sizing: border-box;
  }
}


.gallery-color-picker {
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  &>*:first-child {
    flex: 1;
    padding-right: 10px;
  }

  .color-wrapper {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 20px !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Crect fill='%23ddd' width='5' height='5'/%3E%3Crect x='5' y='5' fill='%23ddd' width='5' height='5'/%3E%3C/svg%3E");
    position: relative;
    background-size: 15px;

    .background {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 20px !important;
      border: 0.5px solid rgba(0, 0, 0, 0.1);
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
}