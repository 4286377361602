@import "styles/theme.scss";

.custom-select {
  height: 1.5em;
  min-width: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 1px;
  position: relative;

  .common-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 12px;
    font-size: 10px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
  }

  .custom-select-arrow,
  .custom-select-arrows {
      @extend .common-select-arrow;
  }

  .custom-select-arrow .custom-select-selection-icon,
  .custom-select-arrows .custom-select-selection-icon {
      color: #f03f3b;
      vertical-align: middle !important;
  }

  &.top-toolbar {
    .custom-select-selector {
      width: fit-content;
    }
  }

  &.font-size-select {
    width: 70px;
  }

  .custom-select-selection-icon {
    transform: translateX(3px);
    svg > path { 
      fill: $primary-color-active;
    }
  }

  .custom-select-selector {
    border-radius: 4px;
    padding: 0 4px 0 0;
    display: flex;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 24px;
    position: relative;
    background-color: #fff;
    border: 1px solid $border-color-base;
    align-items: center;
    cursor: pointer;
    .custom-select-selection-item {
      font-size: $sub-font-size;
      line-height: 22px;
      text-align: initial;
      padding: 0 11px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .anticon-down {
      pointer-events: none;
      font-size: $sub-font-size;
      right: 0;
      margin-left: 11px;
      text-transform: none;
      vertical-align: -0.125em;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
    }
  }

  .custom-select-dropdown-list {
    @include custom-scrollbar;
    display: none;
    position: absolute;
    top: 1.5em;
    min-height: 1em;
    max-height: 13.5em;
    overflow-y: overlay;
    width: 100%;
    border-radius: 4px;
    background: white;

    @supports (-moz-appearance:none) {
      overflow-y: auto;
    }
    
    .custom-select-item {
      color: #2f2c88;
      text-align: initial;

      border-bottom: 1px solid #cbcce0;
      position: relative;
      display: block;
      min-height: 32px;
      padding: 5px 12px;
      font-weight: normal;
      font-size: $label-font-size;
      line-height: 22px;
      cursor: pointer;
      transition: background 0.3s ease;

      &.selected {
        font-weight: 600;
        background-color: #bdbbc7;
      }

      .custom-select-item-content {
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
