@import 'styles/theme.scss';

.library-modal {
  .ant-modal-body {
    padding: 0 24px;
    margin-top: 0;
    border-top: 1px solid #f0f0f0 !important;
    // height: calc(100vh - 350px);
    height: calc(50vh + 58px + 6em);
   }


  .library-modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 0 0 50%;
    margin: 0;

    &.item-copying{
        justify-content: center;
        align-items: center;
    }

    .nav-section {
      width: 50%;
      height: 100%;
      overflow-x: auto;

      & > div {
        .nav-section-col {
          padding: 0 10px;
          width: 100%;
          overflow-y: auto;
        }
      }
    }

    img.item-img {
      width: 100%;
      aspect-ratio: 12/9;
      height: auto;
      filter: drop-shadow(0px 2px 4px rgba(3, 20, 63, 0.2));
      border-radius: 4px;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width:768px) {
    .screen-1 {
      .nav-section-col {
        &:nth-child(2) {
          display: none;
        }
      }
    }

    .screen-2 {
      .nav-section-col {
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }

  @media (min-width:768px) {
  
    .nav-section {
      width: 50%;
      & > div {
        display: flex;
        height: 100%;
        .nav-section-col {
          width: 50%;
          border-right: 1px solid #e2e3e8;
        }
      }

      border-right: 1px solid #e2e3e8;
    }

    
  }
  
}
