.attachment-modal.rich-editor-gallery-edit.simple-info-modal.select-box .ant-modal {
  .ant-modal-body {
    padding: 20px;
    .select-file {
      cursor: pointer;
    }
  }

  @media (min-width:992px) {
    .ant-modal-body {
      padding: 40px;
    }
  }
}
