@import 'styles/theme.scss';

.onboard-wizard-steps {
  padding: 2em;
  border-radius: 8px 0px 0px 8px;
  background: #f5f6fa66 !important;
  overflow: hidden;
  position: relative;
  @include rwd-max(767) {
    padding: 1.3em 1.1em;
  }

  .header-signup {
    margin: auto 3px;
    .full-logo {
      @include rwd-max(767) {
        display: none
      }
    }
    .small-logo {
      @include rwd-min(768) {
        display: none
      }
    }
  }

  .ant-layout-sider-children {
    .ant-steps-item {
      height: 80px;

      &.ant-steps-item-finish {
        .ant-steps-item-title {
          color: $completed-color;
        }
      }
      > .ant-steps-item-container {
        .ant-steps-item-tail {
          top: -34px;
          left: 19px;
          padding: 0 !important;
          &::after {
            margin-left: 9px;
            background-color: initial;
            border-right: 1px solid transparent;
            border-image: repeating-linear-gradient(to bottom, #ababc6 0px, #ababc6 5px, transparent 5px, transparent 10px) 1;
          }
        }

        .ant-steps-item-content {
          overflow: visible;
          & > .ant-steps-item-title {
            font-size: $label-font-size;
            font-weight: 300;
            letter-spacing: 0.5px;
            line-height: 20px;
          }
          @include sm {
            overflow: visible;
            display: none;
            .ant-steps-item-title {
              font-size: 9px;
            }
          }
        }

        .ant-steps-item-title {
          padding-left: 4px;
        }

        .ant-steps-item-tail::after {
          width: 1px;
        }

        &:hover .ant-steps-item-icon {
          border-color: $white-color !important;
        }
      }
    }

    .ant-steps-item-active {
      .ant-steps-item-container {
        .ant-steps-item-content > .ant-steps-item-title {
          color: #05034d;
        }
        .ant-steps-item-tail::after {
          height: 100%;
        }
        .ant-steps-item-icon {
          .ant-steps-icon {
            color: $primary-color-text !important;
            vertical-align: sub;
            font-weight: 700;
          }
        }
      }
    }

    .ant-steps-item-finish > .ant-steps-item-container {
      .ant-steps-item-tail::after {
        background-color: $completed-color;
      }
    }
  }
}

.brand-color-input {
  .brand-color-selector {
    position: relative;
    right: 5px;
  }
  .brand-color-selector, input[type="text"] {
    cursor: pointer;
  }
}

.upload-container {
  text-align: center;
  margin-bottom: 1em;
  .ant-form-item-explain {
    margin-top: 0.3em;
    div {
      color: #4f4e7b;
      opacity: 0.7;
    }
  }
  .logo-uploader {
    max-width: 64px;
    width: 100%;
    height: 64px;
    line-height: 64px;
    border-radius: 8px;
    margin: 0;
    padding: 5px;
    background-color: #f5f6fa;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &.has-image {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      .remove-logo {
        bottom: 74px;
        height: 20px;
        left: 52px;
        cursor: pointer;
        position: absolute;
        right: 0;
        border: none;
        outline: none;
        padding: 2px;

        .anticon {
          background: #fff;
          height: 20px;
          width: 20px;
          line-height: 20px;
          border-radius: 50%;
          padding: 1px;
          vertical-align: middle;
          box-shadow: 0 0.625em 1.25em rgba(4, 3, 49, 0.2);

          svg {
            height: 11px;
            width: 11px;
          }
        }
      }
    }

    .upload-icon {
      line-height: 64px;
      width: 54px;
      height: 54px;
      transform: scale(1) translateX(-50%);
      position: absolute;
      border-radius: 8px;
      border: 1px dashed rgba(5, 3, 77, 0.4);
      -webkit-transform-origin: 0 center;
      transform-origin: 0 center;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .ant-progress-inner {
    width: 60px !important;
    height: 60px !important;
    font-size: 15px !important;
    .ant-progress-text {
      color: #8c8c8c;
    }
  }
}

.account-onboard-layout {
  border-radius: 8px;
  background: #fff !important;
}

.wizard-content {
  text-align: start;
  .wizard-content-container {
    @include thin-custom-scrollbar;
    padding: 30px 30px 0 30px;
    overflow-y: auto;
    height: 560px;
    display: block;
    .heading-wrap {
      padding: 0 0 1.5em;
      text-align: center;
      @include rwd-max(767) {
        padding: 0 0 1em;
      }

      .heading {
        font-size: 1.5em;
        font-weight: 500;
        display: inline-block;
        @include rwd-min(768) {
          font-size: 1em;
        }

        @include rwd-min(1024) {
          font-size: 1.6em;
        }

        @include rwd-max(767) {
          font-size: 1.3em;
        }

        @include rwd-min(2560) {
          font-size: 2em;
        }
      }
      .sub-heading-text {
        font-size: 0.9em;
        font-weight: 300;
      }
      .color-divider {
        background: #f03f3b;
        width: 32px;
        height: 1px;
        padding: 0 10px;
        margin: 0 auto;
      }
    }

    .select-trigger {
      input {
        cursor: pointer;
      }
    }
    .ant-input-group-addon {
      cursor: default;
    }
  }
}
.multi-select.onboard-form {
  .ant-dropdown {
    border-radius: 7px;
  }
  .ant-dropdown-menu {
    border-radius: 7px;
    padding: 0;
    .menu-item {
      .menu-item_ {
        display: flex;
        justify-content: space-between;
        .label {
          color: #05034d;
          span {
            margin-right: 8px;
            svg {
              height: 22px;
              width: 22px;
            }
          }
        }
      }
    }
  }
  .border-bottom {
    &:last-child {
      border-bottom: 0;
    }
  }
}

.ant-modal-wrap {
  z-index: 1002 !important;
}

.sign-up-info-modal {
  width: 680px !important;
  &.final {
    width: 520px !important;
  }

  .asterisk-mark {
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
  }

  .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(4, 3, 49, 0.15);
    border-radius: 4px;
    height: 100%;

    .ant-modal-body {
      padding: 0;

      .title {
        margin-top: 0px;
        justify-content: flex-start;
      }

      .divider {
        margin: 1em 0;
      }

      .body {
        text-align: initial;
        margin: 1.5em 0;
        font-size: 15px;
        &.project-type-question {
          font-weight: 600;
        }
      }

      .ant-steps {
        width: 125%;
        margin-top: 75px;

        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
          border-right: none;
          background: $completed-color;
          background: linear-gradient(to bottom, #01c44f, #01c44f, #42fa8c, #aff6cc, #fbfbfd);
        }

        .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon .anticon {
          svg {
            background: #05034d;
            box-shadow: $box-shadow;
            border-radius: 50%;
          }
        }

        .ant-steps-item-icon .ant-steps-icon {
          top: -6px;
          .anticon {
            svg {
              height: 20px;
              width: 20px;
              border-radius: 50%;
            }
          }
        }
      }

      .ant-steps-dot .ant-steps-item-tail,
      .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        top: 5px !important;
        left: -8px !important;
        margin: 0 0 0 8px;
        padding: 0;
      }

      .ant-steps-dot .ant-steps-item-icon,
      .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        margin-left: 0px;
      }

      .ant-steps-label-vertical {
        .ant-steps-item-active {
          .ant-steps-item-content {
            font-weight: 600;
            letter-spacing: 0.04em;
            opacity: 1;
          }
        }

        .ant-steps-item-content {
          margin-left: -20px;
          text-align: inherit;

          .ant-steps-item-title {
            font-size: 12px;
            color: $label-color-light;
            opacity: 0.8;
            margin-left: 15px;
          }
        }
      }
      .onboard-form {
        margin: auto;
        width: 100%;
      }
      .ant-form {
        .ant-form-item-with-help {
          &.margin-bottom {
            margin-bottom: 1rem;
          }
        }
        .ant-form-item-label {
          > label.ant-form-item-required {
            &::before {
              display: none;
              content: '';
            }
            &::after {
              display: inline-block;
              margin-left: 2px;
              color: #ff4d4f;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: '*';
            }
          }
        }
        .ant-divider {
          display: none;
        }
        .ant-row {
          .ant-form-item-label > label {
            line-height: 140%;
            display: flex;
            color: #4f4e7b;
            opacity: 0.8;
          }

          .ant-form-item-control {
            .ant-form-item-control-input > .ant-form-item-control-input-content {
              .ant-checkbox-wrapper {
                text-align: initial;
                font-size: 12px;
                input {
                  border: 1px solid #e2e3e8;
                  box-sizing: border-box;
                  border-radius: 4px;
                  &:focus {
                    box-shadow: 0 0;
                  }
                }
              }
              input {
                border-radius: 4px;
              }

              .ant-input-group {
                input {
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                }
              }
              .ant-input:focus,
              .ant-input-focused {
                border-color: $primary-color;
              }
              .ant-select > .ant-select-arrow .anticon:not(.ant-select-suffix) {
                pointer-events: none;
              }
            }
            .ant-form-item-explain {
              font-size: 12px;
            }
          }

          .checkbox-grp {
            justify-content: space-between;

            .project-checkbox {
              background: #ffffff;
              border: 1px solid $tab-border;
              box-sizing: border-box;
              border-radius: 4px;
              height: 40px;
              display: flex;
              min-width: 48%;
              margin-bottom: 16px;
              color: $light-text-color;

              &:hover {
                cursor: pointer;
                color: $primary-color-text !important;
                border-color: $primary-color-text !important;
                box-shadow: $box-shadow;
                .anticon {
                  svg {
                    path {
                      fill: $primary-color-text !important;
                    }
                  }
                }
              }

              &.active {
                cursor: pointer;
                color: $primary-color-text !important;
                border-color: $primary-color-text !important;
                box-shadow: $active-box-shadow;
                .anticon {
                  svg {
                    path {
                      fill: $primary-color-text !important;
                    }
                  }
                }
              }

              p {
                margin: auto 0;
              }
            }

            .checkbox-icon {
              padding: 8px;
            }

            .ant-checkbox-wrapper {
              display: none;
            }
          }
        }
      }
      div.button-wrapper {
        position: -webkit-sticky;
        position: sticky;
        display: flex;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1.5em;
        padding: 0 20px;
        justify-content: space-between;
        bottom: auto;

        button {
          background-color: $secondary-color;
          border-color: $secondary-color;
          box-sizing: border-box;
          box-shadow: $box-shadow;
          color: $light-color;
          border-radius: 4px;
          direction: rtl;
          display: flex;
          justify-content: center;
          width: 30%;
          font-size: 14px;
          font-weight: 600;
          height: 44px;

          &:hover {
            background: $secondary-color-hover;
          }

          &.cancel {
            width: 44px;
            min-width: unset;
            background: $light-color;
            border: $light-color;
            color: $primary-color;
            margin: auto 0em;
            .anticon {
              padding: 4px;
              display: block;
              font-size: 18px;
            }
            &:hover {
                background: #f8f8f8;
            }
          }

          &[disabled] {
            opacity: 0.5;
           }

          .button-icon-right {
            height: 25px;
            width: 25px;
            margin: auto 0em;
            .anticon {
              padding: 4px;
              display: block;
              font-size: 18px;
            }
          }
          span {
            margin: auto;
          }
        }
      }
    }
  }
}

.anticon-caret-down {
  &[color='red'] {
    svg {
      path {
        color: #f03f3b;
      }
    }
  }
}

.helper-text-wrapper-step-3 {
  .helper-text-step-3 {
    &.hand-icon {
      margin-right: 0.2em;
      display: flex;
      @include sm {
        margin-right: 1em;
      }
      .anticon {
        margin-top: 8px !important;
        @include sm {
          margin-top: 0 !important;
        }
        svg {
            height: 30px;
            width: 30px;
        }
      }
    }
    &.text {
      margin-left: 0.2em;
      text-align: left;
      @include sm {
        font-size: 11px;
      }
    }
  }
  background: $light-color;
  border-radius: 4px;
  margin-top: -1em;
  padding: 1em;
}

.custom-currency-box {
  padding: 1rem;
  align-items: center;
  justify-content: center;
  .custom-currency-input {
    border: none;
    text-align: center;
    font-size: 16px;
    cursor: default;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    &:focus, &:hover {
      border: none;
      box-shadow: none;
    }
  }
  .primary-btn {
    max-width: 100%;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    box-shadow: rgba(241, 92, 60, 0.4) 0px 10px 15px -4px;
    background: rgb(240, 63, 59);
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    border-color: rgb(240, 63, 59);
    border-image: initial;
    &:hover, &:focus {
      background: rgb(240, 63, 59);
      border-color: rgb(240, 63, 59);
      opacity: 0.9
    }
  }
}
