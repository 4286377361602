@import 'styles/theme.scss';

.select-image {
  text-align: center;
  &p {
    font-size: 16px;
    line-height: 150%;
  }
  .select-image-uploader {
    border: 2px dashed $section-color;
    margin-bottom: 1.2em;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 5px 20px rgba(4, 3, 49, 0.15);
    border-radius: 4px;
    background-color:$section-background;
    p {
      margin-bottom: .5em;
      color: $primary-color-text;
    }
    .select-image-title {
      font-size: 18px;
      line-height: 140%;
      font-weight: 600;
    }
    .select-image-info {
      font-size: 16px;
      line-height: 150%;
    }
    .select-image-details {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.04em;
    }
    .image-size {
      color: #05034d;
      font-size: 10px;
      display: block;
      max-width: 285px;
      margin: 0 auto;
    }
    .select-image-upload-text {
      justify-content: center;
      padding-top: 25px;
      cursor: pointer;
      width: max-content;
      margin: auto;

      .select-image-upload-icon {
        padding: 0 10px;
        svg {
          width: 15px;
          path {
            stroke: $secondary-color;
          }
        }
      }
    }
  }

  .image-preview {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;

    img {
      height: 60px;
      width: 60px;
      border-radius: 8px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      object-fit: cover;
    }
  }

  @media (min-width:992px) {
    .select-image-uploader {
      padding: 35px;
    }
  }
}