@import "styles/theme.scss";

.settings {
  .info-icon {
    margin-top: 0.36rem;
  }

  .margin-bottom {
    margin-bottom: 30px;
  }

  .beta-tag {
    justify-content: center;
    padding: 4px 16px;
    min-width: 60px;
    width: 60px;
    height: 26px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.04em;
    background: rgb(252, 243, 236);
    color: rgb(233, 109, 40);
    &.margin-start {
      margin-left: 8px;
    }
  }

  .blur-overlay {
    position: relative;
    transition:
      backdrop-filter 0.4s ease,
      background-color 0.4s ease;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      background-color: rgba(255, 255, 255, 0.6);
      transition:
        backdrop-filter 0.4s ease,
        background-color 0.4s ease;
      pointer-events: none;
      z-index: 1;
    }
    > * {
      pointer-events: none;
      z-index: 0;
    }
  }

  .ant-select-multiple {
    .ant-select-selection-item {
      background: #f5f6fa;
      border: none;
    }
    .ant-select-selection-item-remove {
      color: #000000;
    }
  }
  .signature-canvas {
    position: relative;
    .ant-image {
      width: 368px;
      height: 152px;
      border-radius: 4px;
      img {
        width: 368px;
        height: 152px;
      }
    }

    .clear-button {
      position: absolute;
      right: 0;
      border: none;
      outline: none;
      padding: 2px;
      height: 10px;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  // Button.learn-more {
  //   margin-left: -0.3em;
  //   border: none;
  //   background: $light-color;
  //   color: $primary-color-text;
  //   &:hover {
  //     color: $primary-color-text;
  //   }
  //   border-radius: 0.2em;
  // }

  // .cname {
  //   margin-left: -0.8em;
  //   margin-top: 1em;
  //   margin-bottom: -1em !important;
  //   width: 30em;
  //   margin-right: 0.1em;
  // }

  // .domain {
  //   margin-left: -0.8em;
  //   margin-bottom: -1em !important;
  //   width: 30em;
  //   margin-right: 0.1em;
  // }

  // .upgrade {
  //   height: 2.1em;
  //   width: 6em;
  //   position: absolute;
  //   background-color: $transform-color;
  //   left: 50%;
  //   transform: translate(-50%, -75%);
  //   text-align: center;
  //   border-radius: 20em;
  // }

  .uploading-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .anticon {
      font-size: $label-font-size;
      margin-right: 10px;
    }
  }

 
  .settings-form {
    background: $white-color;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(3, 20, 63, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.5rem;

    .settings-actions {
      padding: 20px!important;
      border-top: 1px solid $tab-border;
    }

    .content {
      padding: 10px;
      & > div {
        padding: 10px;
      }

      .input-container {
        height: fit-content;
        margin-bottom: 0px;
        input {
          height: 32px;
        }
        .ant-input-affix-wrapper {
          padding: 4px 4px 4px 12px;
          .ant-input {
            height: 27px !important;
          }
          .ant-input-suffix {
            height: 26px;
            .ant-btn {
              font-size: 12px;
              height: 26px;
            }
          }
        }
        .signed-email-add-btn {
          background: $tab-background;
          border-radius: 4px;
          font-weight: 400;
          font-size: $label-font-size;
          color: $primary-color-text;
          border: 0;
        }
        .signed-email-content {
          justify-content: flex-start;
          margin: 1em 0;
          .ant-tag {
            background: $tab-background;
            border-radius: 3em;
            border: 0;
            margin: 0.5em 0.5em 0.5em 0;
            display: flex;
            padding: 0.5em 1em;
            font-weight: 600;
            font-size: $sub-font-size;
            height: 2em;
            line-height: 1em;
            .ant-tag-close-icon {
              margin-top: -0.3em;
              .anticon svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }

    .input-container {
      width: 100%;
    }

    .form-item,
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
      &.mb-0 {
        margin-bottom: 0;
      }
      .form-label {
        padding-bottom: 4px;
        font-size: $label-font-size;
        opacity: 1;
        font-weight: 400;
        color: #8f8fb9;
        display: flex;
        justify-content: space-between;
        text-transform: capitalize;
        &.transform-none {
          text-transform: none;
        }

        .contact-color {
          color: #8f8fb9 !important;
          font-size: $label-font-size;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400 !important;
          line-height: 140%;
          margin-left: 5px !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          box-shadow: 0px 2px 4px rgba(47, 44, 136, 0.2);
          border-color: $primary-color;
        }
      }
      .ant-select-selection-item {
        color: $primary-color-text;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
      input {
        color: $primary-color-text;
      }
      .contact-button-bgx {
        border-radius: 4px;
        border: 1px solid;
        border-color: #e2e3e8 !important;
        padding: 20px 10px 32px !important;
        .ant-select,
        .ant-input {
          width: 100% !important;
        }
      }
      .form-label-closer {
        padding-bottom: 1px !important;
      }
    }
    .input-no-bottom {
      margin-bottom: 0 !important;
    }
    .input-mbpl-5 {
      margin-bottom: 35px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
      &:last-child {
        margin-bottom: 0 !important;
      }
      .contact-option-input {
        padding: 0 !important;
        margin-bottom: 0px !important;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .full-width-input {
      width: 100% !important;
    }
  }

  .settings-global {
    .ant-select-selection-item {
      margin-bottom: 0.5em;
    }
  }

  .ant-select-selector {
    height: 2.3em !important;
    border-radius: 0.2em !important;
  }

  .ant-input {
    height: 2.3em !important;
    border-radius: 0.2em !important;
  }

  .settings-column {
    border: 1px solid $tab-border;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 110px;
    position: relative;
    &.illustration {
      background-color: #f5f6fa;
    }

    &.signature-editor-disabled {
      pointer-events: none;
      input,
      canvas {
        background-color: $input-disabled-color;
      }
    }

    canvas {
      width: 100%;
      padding: 1px;
    }
    &.extended {
      height: 154px;
    }

    .signature-img {
      cursor: pointer;
      width: 100%;
      height: 154px;
      object-fit: contain;
      &.anticon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          height: 40px;
        }
      }
    }

    .typed-signature {
      font-family: Sacramento, sans-serif;
      font-size: 2.815em;
      padding: 5px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      margin: auto;
      border: 0;
      text-align: center;
      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }

  .pdf-footer,
  .ai-tool-toggle {
    display: flex;
    align-items: center;
    button {
      margin-right: 7px;
    }
    .check-label {
      font-size: 16px;
      color: #05034d;
      margin-bottom: 0 !important;
    }
    #show-footer.ant-switch {
      height: 21px;
      min-width: 35px;
    }
    .ant-switch-handle {
      top: 3px;
      width: 15px;
      height: 15px;
    }
    .ant-switch-checked {
      background-color: #2f2c88;
      .ant-switch-handle {
        left: calc(100% - 16px - 2px);
      }
    }
  }

  .select-custom-suffix {
    &.variables {
      .ant-select-selector {
        height: 24px !important;
        border: none;
        background-color: #f5f6fa;
      }
      .ant-select-arrow {
        &:after {
          border-top: 5px solid #2f2c88;
        }
        .anticon {
          color: #2f2c88 !important;
        }
      }
    }
  }

  .title-wrap {
    padding: 20px 20px 0;

    h3 {
      font-weight: 600;
      font-size: 24px;
      color: $primary-color-text;
      margin-bottom: 8px;
      line-height: 1;
    }
    p {
      max-width: 50em;
      font-size: $label-font-size;
      color: $primary-color-text;
      margin-bottom: 0;
    }
  }

  .signature-btn-wrapper {
    position: absolute;
    top: 0px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 154px;
    .anticon {
      display: none;
      color: white;
    }
    &:hover {
      .anticon {
        font-size: 1.875em !important;
        display: block;
      }
      background-color: $signature-hover-background;
      border-radius: 4px;
    }
    input[type="file"] {
      cursor: pointer;
      position: absolute;
      top: 0px;
      width: 388px;
      height: 154px !important;
      opacity: 0;
    }
  }

  .signature-input {
    .form-item {
      margin-bottom: 20px !important;
    }
  }

  .signature-section,
  .general-section,
  .pdf-options {
    .content {
      padding: 0 20px 0;
    }
    .title-wrap {
      padding: 40px 0 0 40px;

      h3 {
        font-weight: 600;
        font-size: 24px;
        color: $primary-color-text;
        margin-bottom: 8px;
      }

      p.section-info {
        max-width: 50em;
        font-size: $label-font-size;
        color: $primary-color-text;
        margin-bottom: 20px;
      }
    }
    .title-input {
      pointer-events: none;
    }
    .illustration-wrap {
      position: relative;
      overflow: hidden;
      .footer-illustration {
        background-color: #fff;
        border-radius: 8px;
        padding: 0 20px 10px;
        position: relative;
        overflow: hidden;

        &.is-preview {
          top: -7px;
          left: 38px;
          width: 590px;
          transform-origin: left top;
          transform: scale(0.86);
          box-shadow: 5px 3px 10px 0px rgba(130, 130, 130, 0.1);

          .prop-body {
            p {
              color: #747474;
              letter-spacing: 0.5px;
              word-spacing: 0.5px;
              white-space: nowrap;
            }
          }

          .divider {
            opacity: 0.4;
            width: 590px;
          }

          .footer-text {
            color: #b6b6b6;
          }
        }

        &.footer-active {
          height: 140px;
          transition: height 0.3s ease;

          .prop-footer {
            opacity: 1;
            transition: height 0.3s ease;
          }
        }

        &.footer-inactive {
          height: 100px;
          transition: height 0.3s ease;

          .prop-footer {
            opacity: 0;
            transition: opacity 0.3s ease;
          }
        }
      }
    }
  }

  .signature-section {
    .signature-section-info {
      color: #05034d;
    }
  }

  .projects-type {
    p.projects-type-info {
      font-size: $label-font-size;
      color: $primary-color-text;
      margin-bottom: 20px;
    }
    .ant-col {
      padding: 15px;
      margin: 0;
    }
    .other-project {
      .form-item {
        padding: 0px;
      }
      textarea {
        height: 149px;
        border: 1px solid $tab-border;
        border-radius: 4px;
        resize: none;
      }
    }

    .checkbox-grp {
      display: flex;

      &.checkbox-grp-disabled {
        .project-checkbox {
          background: $input-disabled-color;
          pointer-events: none;
        }
      }

      .project-checkbox {
        padding: 15px;
        border: 1px solid $light-link-border-color;
        border-radius: 4px;
        position: relative;

        .ant-checkbox {
          display: none;
          &.ant-checkbox-checked {
            display: inline-block;
          }
        }
        .checkbox-icon {
          background-color: #f5f6fa;
          border-radius: 6px;
          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            filter: saturate(40%);
            opacity: 0.3;
            background-position: center;
            background-size: cover;
          }
        }

        .design-icon:before {
          background-image: url(https://prospero.s3.us-west-2.amazonaws.com/DesignActiveIcon.svg);
        }
        .web-icon:before {
          background-image: url(https://prospero.s3.us-west-2.amazonaws.com/DevelopmentActiveIcon.svg);
        }
        .video-icon:before {
          background-image: url(https://prospero.s3.us-west-2.amazonaws.com/VideoActiveIcon.svg);
        }
        .copy-icon:before {
          background-image: url(https://prospero.s3.us-west-2.amazonaws.com/CopywritingActiveIcon.svg);
        }
        .marketing-icon:before {
          background-image: url(https://prospero.s3.us-west-2.amazonaws.com/MarketingActiveIcon.svg);
        }
        .other-icon:before {
          background-image: url(https://prospero.s3.us-west-2.amazonaws.com/OtherActiveIcon.svg);
        }

        &:hover,
        &.active {
          cursor: pointer;
          color: $primary-color-text !important;
          border-color: $primary-color-text !important;
          box-shadow: 0 1px 4px 0 #d9d9d9;

          .checkbox-icon:before,
          .checkbox-icon:before {
            filter: unset;
            opacity: 1;
          }
        }

        p {
          font-size: 16px;
          margin-bottom: 0;
        }
      }
      .label-icon {
        display: flex;
        align-items: center;
        gap: 15px;
      }
      .checkbox-icon {
        width: 35px;
        height: 35px;
        position: relative;
      }
      .ant-checkbox-wrapper {
        position: absolute;
        top: 5px;
        right: 5px;
        .ant-checkbox-inner {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .reset-btn {
    .anticon {
      margin-right: 2px;
    }
  }

  @include rwd-min(1200) {
    .settings-form {
      padding: 30px 20px;

      .title-wrap {
        padding: 0 40px;
      }

      .content {
        & > div {
          padding: 15px 20px;
        }
      }

      .settings-actions {
        padding: 40px;
      }
    }
  }
}

.ai-assistant-tooltip {
  width: 26em !important;
  max-width: 26em !important;
}

.reset-btn {
  height: 44px !important;
  min-width: 80px;
  border: none !important;
  background-color: transparent !important;
  color: $primary-color-text !important;
  font-size: 16px !important;
  font-weight: 500;
  align-items: center;
  display: flex;
  margin-right: 10px;
  box-shadow: none !important;
  .reset-icon {
    margin-right: 5px;
    svg {
      height: 16px;
    }
  }
  .prosper-button-text {
    text-decoration: none !important;
  }
}

.expiry-settings {
  .custom-box {
    padding: 0 8px 4px;
    width: 100%;
    & > .ant-space-item:first-child {
      flex-grow: 9;
    }
    .ant-btn {
      background-color: $light-color;
    }
  }
  .ant-divider-horizontal {
    margin: 4px 0 !important;
  }
}

.select-custom-suffix {
  .ant-select-arrow {
    &:after {
      content: "";
      border: 5px solid transparent;
      border-top: 5px solid $secondary-color;
      position: absolute;
      top: 3px;
      right: 0;
    }
    .ant-select-suffix {
      display: none;
    }
  }
}

.font-trash-icon,
.font-loading {
  display: none !important;
  position: absolute;
  right: 16px;
  top: 13px;
  cursor: pointer;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.ant-select-dropdown {
  padding: 0 !important;

  .anticon-check {
    display: none;
  }
  .ant-select-item-group {
    font-size: 15px;
    font-weight: 500;
    color: #05034d;
  }
  .ant-select-item {
    &.divider {
      background-color: #e2e3e8;
      min-height: 1px;
      padding: 0px;
      margin: 0 12px;
    }
  }

  .ant-select-item-option-content {
    font-weight: 400;
  }

  .custom-font-item {
    &:hover {
      .font-trash-icon {
        display: inline-block !important;
        opacity: 1;
      }
    }
  }

  .ant-select-item-option-selected {
    background-color: white !important;
    .ant-select-item-option-content {
      &:after {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url('data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHJ4PSI4IiBmaWxsPSIjMDA1QUZGIi8+CiAgICA8cGF0aCBkPSJNNCA4LjVMNyAxMUwxMiA1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CiAgPC9zdmc+Cg==');
        background-size: cover;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        transition: opacity 0.5s ease;
      }
    }
    &.custom-font-item {
      .font-trash-icon {
        right: 40px;
      }
    }
    .ant-select-item-option-content {
      color: #005aff;
    }
    &:hover {
      background-color: #f5f5f5 !important;
    }
  }
  .font-loading {
    display: inline-block !important;
  }
  .font-upload-option {
    align-items: center;
    width: 100%;
    border-top: 1px solid #e2e3e8;
    background-color: #f5f6fa !important;
    padding: 1em;
    color: #2f2c88;
    min-height: 32px;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    span {
      margin-left: 5px;
    }
  }
}

.font-select-with-upload {
  .rc-virtual-list-holder-inner {
    box-shadow: none !important;
  }
}
