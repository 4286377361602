@import "styles/theme.scss";

.publish-signature-modal {
  &.language-rtl {
    .ant-modal-close {
      left: 0 !important;
      right: unset !important;
    }
  }

  .sign-type-wrapper {
    @extend .flex-center;
    border-radius: 8px;
    background: $light-color;

    .sign-tab {
      flex: 1;
      text-align: center;
      padding: 8px;
      cursor: pointer;
      border-radius: 8px;
      span {
        color: $link-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;

        @include sm {
          font-size: 14px;
        }
      }

      &.active {
        background: #DADBEB;
      }
    }
  }

  .sig-draw {
    canvas {
      border: 1px solid $tab-border;
    }
  }

  .upload-icon>svg {
    fill: $link-color;
  }

  .signature-text {
    padding-top: 29px !important;
  }

  @include sm {
    .button-wrapper {
      flex-wrap: wrap;

      .ant-btn:nth-child(1) {
        width: 100% !important;
      }

      .ant-btn:nth-child(2),
      .ant-btn:nth-child(3) {
        width: calc(50% - 5px);
      }
    }
  }
}