@import "styles/theme.scss";

.draft-editor {
  .simple-section {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px;
    position: relative;
    // section buttons overlaps color picker
    &:hover{
      z-index: 2;
    }

    .simple-section-container {
      .simple-section-title-block {
        .simple-section-title,
        .simple-section-sub-title {
          margin-top: 0px;
          margin-right: 0.5em;
          margin-bottom: 0px;
          font-family: "Sharpsansno1 semibold", sans-serif;
          font-size: 36px;
          position: relative;
        }
        .simple-section-sub-title {
          line-height: 20px !important;
          padding-top: 4px !important;
        }
      }

      .simple-section-content {
        display: flex;
        padding-top: 8px;
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 28px;
        color: #4a4a4a;

        // to show the image toolbar on top of everything on the editor
        .ant-col:hover{
          z-index: 11;
        }
      }
    }
  }
    &.language-rtl {
      .simple-section {
        .simple-section-container {
          .simple-section-title-block {
            .simple-section-title,
            .simple-section-sub-title {
              margin-right: 0;
            }
          }
        }
      }
    }

  .element-no-padding {
    padding: 0 !important;
  }

  @media screen and (min-width: 768px) {
    .simple-section {
      padding: 30px;
    }
  }
  
  @media screen and (min-width: 992px) {
    .simple-section {
      padding: 20px 50px 0;
    }
  }

  iframe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px;
    page-break-before: always;
    border: none;
    overflow: hidden !important;
  }
}

.section-wrapper {
  .simple-section {
    &.editor-layout-position-top-middle {
      .simple-section-title-block{
        text-align: center;
      }
      .simple-section-title,
      .simple-section-sub-title {
        min-width: 330px;
        max-width: 100%;

        .public-DraftEditorPlaceholder-inner,
        .public-DraftEditor-content,
        .public-DraftStyleDefault-rtl,
        .public-DraftStyleDefault-ltr {
          text-align: center!important;
        }
      }
    }
    &.editor-layout-position-large {
      .simple-section-title-block {
        padding-right: 10px;

        .public-DraftEditorPlaceholder-inner{
          word-break: break-word;
          // text elipses (...) is not working with break-word
          // so adding a white fading gradient instead
          &:before{
            content: '';
            height: 45%;
            width: 75px;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(90deg, transparent 1%, $white-color 50%);
            position: absolute;
          }
        }

        .public-DraftEditorPlaceholder-root ~ .DraftEditor-editorContainer {
          min-height: 110px;
          cursor: text;
        }
      }
    }
  }
}

.toolbar-side-menu {
  // used in library section in sidemenu
  .simple-section-content-multiple {
    display: flex;
  }
  .simple-section-content {
    p {
      word-break: break-all;
      margin-bottom: 0;
    }
  }
}

.prevent-drop-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
