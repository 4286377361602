@import 'styles/theme.scss';
.integrations-menu-wrapper {
  padding: 0 10px 10px !important;
  left: unset!important;
  right: 0 !important;
  top: 64px !important;
  overflow-y: auto;
  max-height: calc(100vh - 140px);
  z-index: 1007 !important;
  border-radius: 8px;
  background: $white-color;
  box-shadow: 0px 0.625em 1.25em rgba(4, 3, 49, 0.2);
  position: fixed!important;
  width: 100%;
  max-width: 950px;

  .integration-menu {
    &.ant-dropdown-menu {
      box-shadow: none  !important;
    }
    .ant-dropdown-menu-item-active {
      background-color: $white-color !important;
      box-shadow: none  !important;
    }
    .ant-dropdown-menu-item {
      padding: 0;
      white-space: unset !important;
    }
  }

  @include rwd-min(992) {
    padding: 2px 16px 16px !important;

    .integration-item {
      padding: 20px 30px;
      position: relative;
      & > div:nth-child(1) {
        flex : 1;
        max-width : 100%;
      }
      & > div:nth-child(2) {
        position: absolute;
        top: 22px;
        right: 30px;
        width: 500px;
      }
      .item-brand-name{
        max-width: 40%;
      }
    }
  }
}
.integration-menu {
  min-height: 300px;

  .integration-wrapper {
    margin: 0;
    .buttons {
      text-align: right;
      .ant-btn:not(:first-child) {
        margin-left: 5px;
      }
    }
    .button {
      height: 32px;
    }
 

    .item-name {
      font-size: 18px;
    }
    .value {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: 768px) {
        margin-top: 12px;
      }
      .integration-link {
        padding-right: 1em;
        @media screen and (min-width: 768px) {
          padding-bottom: 1.5em;
        }
      }
    }
    .integration-item {
      min-width: 300px;
      max-width: 900px;
      &:hover {
        box-shadow: 0px 0.33em 0.52em rgba(129, 129, 129, 0.2);
      }
      &:nth-child(1) {
        align-items: flex-start;
      }
      .code {
        overflow-x: hidden;
      }
    }
  }
}
