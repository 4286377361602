@import 'styles/theme.scss';

.user-selection-modal {
  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-body {
    padding: 20px 20px 20px;
    text-align: center;

    .chatway-icon {
      svg {
        height: 50px;
        width: 50px;
      }
    }

    h1 {
      font-weight: 600;
      font-size: 24px;
      color: $primary-color-dark;
    }

    .sub-heading {
      font-size: 16px;
      color: #010032;
      margin: 0 auto 20px;
      max-width:500px;
    }

    .color-divider {
      background: $secondary-color;
      width: 32px;
      height: 1px;
      padding: 0 10px;
      margin: 10px auto;
    }

    .type-selector {
      cursor: pointer;

      h2 {
        font-size: 1.25em;
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 18px;
      }

      .circle {
        height: 160px;
        width: 160px;
        border-radius: 50%;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 37px;
      }

      .explainer {
        font-weight: 300;
        font-size: 0.875em;
        margin: 0;

        b {
          font-weight: 600;
        }
      }

      .box {
        box-sizing: border-box;
        border-radius: 4px;
        background: $white-color;
        border: 1px solid $light-link-border-color;
        padding: 10px;
        margin-bottom: 20px;
        position: relative;
        color: $primary-color-dark;

        h2 {
          color: inherit;
        }

        .explainer {
          opacity: 0.8;
          font-size: 14px;
        }

        img {
          width: 80%;
          max-width: 230px;
          margin: 10px 0 25px;
          border-radius: 0.5rem;
          background: #F6F5FB;
          box-shadow: 0px 11px 30px 0px rgba(23, 20, 115, 0.15);
        }

        &:hover {
          background: $primary-color;
          border: 1px solid $primary-color;
          box-shadow: 0px 20px 25px rgba(47, 44, 136, 0.25);
          color: $white-color;
        }
      }
    }
  }

 
  @include rwd-min(360) {
    .ant-modal-body {
      .type-selector {
        display: flex;
        gap: 10px;
        .box {
          width: 50%;
          img {
            width: 80%;
          }
        }
      }
    }
  }

  @include rwd-min(576) {
    .ant-modal-body {
      padding: 50px;

      .sub-heading {
        margin-bottom: 30px;
      }

      .type-selector {
        display: flex;
        justify-content: center;

        h2 {
          font-size: 20px;
        }
      }

      .box:nth-child(1) {
        margin-right: 15px;
      }

      .box:nth-child(2) {
        margin-left: 15px;
      }
    }
  }


  @include rwd-min(992) {
    .ant-modal-body {
      padding: 50px 80px;

      .type-selector {
        gap: 30px;

        h2{
          margin: 15px 0;
        }

        .explainer{
          padding:0 30px;
        }
      }
    }
  }
}



.anticon.close-icon {
  &:hover {
    background: $light-color;
    border-radius: 50%;
    padding: 4px 4px;
  }
}