@import 'styles/theme.scss';

.rich-editor-components-wrapper {
  &:hover {
    .rich-editor-testimonial {
      box-shadow: 0 0 0 2px $section-color;
    }
  }

  .testimonial-wrapper {
    padding-top: 10px;
  }
}

.rich-editor-testimonial {
  cursor: default;
}

figure.testimonial-wrapper {
  margin-top: 20px;
}

.testimonial-wrapper {
  border-radius: 8px;
  text-align: left !important;
  ::before {
    display: none;
  }

  ::before {
    display: none;
  }

  .testimonial-item {
    position: relative;

    .quote-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 3rem;
      height: 3rem;
      opacity: 0.08;

      svg {
        width: 100%;
      }
    }

    .testimonial-content {
      display: flex;
      align-items: flex-start;
      padding: 2rem;
    }

    .avatar-wrapper {
      min-width: 80px;

      .dropzone {
        height: 68px;
        width: 68px;
        background-color: unset;
        border-radius: 50%;
        position: relative;
        border: 2px dashed transparent;

        &:hover {
          border-color: $section-color;
        }

        &.active {
          border: 2px dashed $success-color;
        }

        &.reject {
          border: 2px dashed $error-color;
        }
      }

      .avatar {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 8px 24px 0px #00000029;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M15 12H9.00002C7.40872 12 5.88259 12.6321 4.75738 13.7574C3.63216 14.8826 3.00002 16.4087 3.00002 18V19C2.99926 19.1316 3.02448 19.2621 3.07425 19.3839C3.12401 19.5057 3.19734 19.6166 3.29002 19.71C3.53002 19.94 5.77002 22 12 22C18.23 22 20.47 19.94 20.71 19.71C20.8027 19.6166 20.876 19.5057 20.9258 19.3839C20.9756 19.2621 21.0008 19.1316 21 19V18C21 16.4087 20.3679 14.8826 19.2427 13.7574C18.1174 12.6321 16.5913 12 15 12Z' fill='%23C9C8E6' /%3E %3Cpath d='M12 14C15.3137 14 18 11.3137 18 8C18 4.68629 15.3137 2 12 2C8.68629 2 6 4.68629 6 8C6 11.3137 8.68629 14 12 14Z' fill='%237876C1' /%3E %3C/svg%3E");
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;

        &.uploading {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .text-wrapper {
      flex: 1;
      color: inherit;
      line-height: 1.4;
      overflow: hidden;

      .name {
        position: relative;
        font-weight: 500;
        margin-bottom: 0;
        min-height: 28px;
      }

      .text {
        position: relative;
        font-size: 0.8em;
        opacity: 0.8;
        min-height: 28px;

        div {
          word-wrap: break-word;
          overflow-wrap: break-word;
        }

        .editable {
          height: calc(100% + 1.5em);
        }

        .pseudo {
          margin-bottom: 1em;
        }
      }

      .pseudo {
        opacity: 0;
        word-break: break-all;
      }

      .editable {
        position: absolute;
        top: 0;
        font-size: inherit;
        font-weight: inherit;
        height: 100%;
        min-height: 28px;
        line-height: inherit;
        width: 100%;
        border: unset;
        background-color: unset;
        resize: none;
        padding: 0 !important;
        display: block;
        overflow: hidden;

        &:focus {
          outline: none;
        }
      }
    }

    hr {
      margin: auto;
      width: calc(100% - 4rem);
      opacity: 0.2;
    }

    &:last-child {
      hr {
        display: none;
      }
    }
  }

  &.large-font {
    .text-wrapper {
      line-height: 1.2;
    }

    .name {
      margin-top: -0.1em;
      margin-bottom: 0.3em !important;
    }
  }

  &.horizontal {
    .testimonial-content {
      flex-direction: column;
      align-items: center;
      padding-bottom: 6rem;
    }

    .avatar-wrapper {
      margin-bottom: 2rem;
    }

    .text-wrapper {
      width: 100%;
      max-width: 300px;

      &>*,
      .editable {
        text-align: center;
      }
    }

    .quote-icon {
      top: unset;
      bottom: 20px;
      left: calc(50% - 1.5rem);
    }
  }

  &.vertical {
    .name {
      width: calc(100% - 30px);
    }
  }

  &.white-text {
    .editable::placeholder {
      color: #fff;
      opacity: 0.6;
    }
  }
}

.language-rtl,
.language-hebrew {
  .testimonial-item {
    .quote-icon {
      right: unset !important;
      transform: rotateY(180deg);
    }
  }

  .vertical {
    .testimonial-item {
      .quote-icon {
        left: 12px;
      }

      .text-wrapper {
        text-align: right;
      }
    }
  }
}


.publish {
  .testimonial-item {

    .name,
    .text {
      word-wrap: break-word;
    }
  }
}