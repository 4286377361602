.upload-content-library-item-modal {
  .select-image {
    .ant-upload-drag {
      .ant-upload-btn {
        padding: 0;
        .select-image-uploader {
          margin-bottom: 0;
          height: 100%;
          .image-size {
            color: #05034d;
            font-size: 10px;
            display: block;
            width: 80%;
            max-width: 325px;
            margin: 0 auto;
          }
        }
      }
    }
    .ant-upload-list-item-info {
      & > span {
        display: flex;
      }
    }
  }
  .fade {
    opacity: 0.8 !important;
  }
}
