@import "styles/theme.scss";

.email-content {
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-radius: var(--3x, 24px);
    border: 1px solid #F0EFEF;
    background: $white-color;

    .email-body {
        width: 100%;
    }

    p> {
        margin-bottom: 12px;
    }

    .word-wrap {
        word-wrap: break-word;
        /* Old name for overflow-wrap */
        overflow-wrap: break-word;
        /* Preferred modern usage */
    }
}

.brand-logo {
    max-height: 73px !important;
}

.preview-text-section {
    width: 100%;
    padding: 32px;
    flex-direction: column !important;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: $light-color;

    .preview-text {
        color: $primary-color-dark;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        max-width: 100%;
    }

    .preview-action-button {
        border-radius: 6.592px;
        padding: 7.771px 20.722px;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: 600;
        font-size: 15.542px;
        gap: 6.592px;
        letter-spacing: 0.622px;
        height: auto !important;
        cursor: auto;
        pointer-events: none;
        border: 0;
    }
}

.power-by-section {
    gap: 8px;
    align-items: center;

    .power-by-text {
        color: $primary-color-dark;
        font-size: 14px;
        font-weight: 400;
        opacity: 0.5;
    }
}

.email-preview-modal {
    box-shadow: 0px 5px 20px 0px rgba(4, 3, 49, 0.15);
    padding: 0 !important;

    .scrollable-section {
        max-height: calc(100vh - 20vh);
        overflow-y: auto;
        padding-bottom: 20px;
        padding: 0px 33px 24px 33px;
    }

    .subject-section {
        flex-direction: column;
        gap: 10px;
        padding-top: 23px;

        .subject {
            >span {
                color: $primary-color-dark;
                font-size: 24px;
                font-weight: 400;
            }
            :first-child {
                opacity: 0.6;
            }
        }

        .user {
            >span {
                color: $primary-color-dark;
                font-size: 16px;
            }

            :first-child {
                font-weight: 400;
                opacity: 0.6;
            }
            :last-child {
                font-weight: 600;
            }
        }
    }

    .header-section {
        padding: 24px 33px 0px 33px;
        background: $white-color;
        box-shadow: 0px 2px 16px 0px rgba(3, 20, 63, 0.11);
        display: flex;
    }

    .ant-modal-body {
        background: $light-color;
        padding: 0px !important;
    }

    .page-header {
        gap: 16px;
        justify-content: flex-start;
        align-items: center;
        margin: 0 !important;
        display: flex;

        .page-title {
            font-size: 24px;
            margin-bottom: 16px;
            font-weight: 600;
            line-height: 140%;
        }
    }

    .extra-wrapper {
        gap: 8px;
        display: flex;
        margin-left: 16px;
        margin-top: 3px;

        button {
            display: flex;
            padding: 6px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            border: 1px solid $tab-border;
            background: transparent;
            line-height: 140%;
            /* 19.6px */

            :last-child {
                color: $link-color;
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                margin: 0;
            }

            .anticon {
                height: 20px;
            }
        }
    }

    .email-content {
        margin: 24px 0px !important;
        border-radius: 8px !important;
    }

    .footer-section {
        flex-direction: column;
        padding: 0 40px;
        gap: 24px;

        .footer-heading {
            color: $primary-color-dark;
            font-size: 14px;
            font-weight: 600;
            opacity: 0.6;
        }

        .footer-description {
            color: $primary-color-dark;
            font-size: 14px;
            font-weight: 400;
            opacity: 0.6;
        }

        .attachment-title {
            :first-child {
                color: $primary-color-text;
                font-size: 14px;
                font-weight: 600;
            }

            :last-child {
                color: #636671;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}