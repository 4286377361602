.tag-button {
  border: 1px solid transparent;
  border-radius: 4px;
  height: 24px;
  font-size: 12px;
  padding: 2px 6px;
  font-weight: 500;
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
  user-select: none;
}
