@import 'styles/theme.scss';

.expired-proposal {
  &.back-button {
    position: fixed;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    text-align: right;
    height: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    background: #f5f6fa !important;
    border: 2px solid #f5f6fa !important;
    color: #05034d !important;
    z-index: 9999;
  }
}
.expired-proposal-modal {
  .ant-modal-close {
    display: none !important;
  }
  .ant-modal-body {
    padding: 50px 20px;

    .divider {
      margin: 0 auto;
      margin-top: 1em;
      background: $secondary-color;
      width: 32px;
      height: 1px;
    }
    .title {
      display: flex;
      justify-content: center;
      margin-top: 1em;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      text-align: center;
      color: $primary-color-text;
    }
    .body {
      width: 80%;
      margin: 1.5em auto 1em;
      text-align: center;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: $primary-color-text;
    }
    .button-wrapper {
      display: flex;
      justify-content: space-around;
      margin-top: 2em;
      .ant-space{
        flex-wrap: wrap;
        justify-content: center;
      }
      .ant-btn{
        margin: 0 5px;
        @include sm(){
          flex: 1;
          margin: 15px 0;
        }
      }
    }
  }

  &.hebrew, &.arabic {
    .button {
      direction: rtl;
      .anticon {
        margin: 0 5px;
      }
    }
  }
}
