.color-popover{
  .ant-popover-inner {
    border-radius: 8px !important;
  }
}
.chrome-picker {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif !important;

  >* {
    user-select: none;
  }

  &>div:nth-child(1) {
    border-radius: 8px 8px 0 0 !important;
  }


  &>div:nth-child(2) {
    &>.flexbox-fix:nth-child(1)>div:nth-child(1)>div:nth-child(1) {
      // color display
      margin-top: 0 !important;
      height: 20px !important;
      width: 20px !important;

      &>div:nth-child(1) {
        border-radius: 10px !important;
      }
    }

    // hue bar
    &>.flexbox-fix:nth-child(1)>div:nth-child(2)>div:nth-child(1) {
      height: 20px !important;
    }

    // alpha bar
    &>.flexbox-fix:nth-child(1)>div:nth-child(2)>div:nth-child(2) {
      height: 10px !important;
    }


    // up down arrows
    &>div:nth-child(2)>div:nth-child(2)>div {
      margin-top: 4px !important;
    }
  }

  input {
    border-radius: 6px !important;
    height: 30px !important;
    border: 0.5px solid rgba(0, 0, 0, 0.1) !important;
    box-shadow: unset !important;
    font-size: 12px !important;
    color: #777;

    &:focus {
      outline: 1px solid #005aff;
    }
  }

  label {
    margin-top: 5px !important;
  }

  .hue-horizontal {
    border-radius: 6px;

    // hue selector marker
    &>div>div {
      height: 22px !important;
      border-radius: 4px !important;
      opacity: 0.8;
    }
  }
}