@import 'styles/theme.scss';

$header-height: 48px;

/**
 * @providesModule DraftEditor
 * @permanent
 */
/**
 * We inherit the height of the container by default
 */
/**
 * Zero-opacity background used to allow focus in IE. Otherwise, clicks
 * fall through to the placeholder.
 */
/**
 * @providesModule DraftEditorPlaceholder
 */
/**
 * @providesModule DraftStyleDefault
 */
/* @noflip */
/* @noflip */
/**
 * These rules provide appropriate text direction for counter pseudo-elements.
 */
/* @noflip */
/* @noflip */
/**
 * Default spacing for list container elements. Override with CSS as needed.
 */
/**
 * Default counters and styles are provided for five levels of nesting.
 * If you require nesting beyond that level, you should use your own CSS
 * classes to do so. If you care about handling RTL languages, the rules you
 * create should look a lot like these.
 */
/* @noflip */
/* @noflip */
/* @noflip */
/* @noflip */
/* @noflip */
/* @noflip */
/* @noflip */
/* @noflip */
/* @noflip */
/* @noflip */
/**
 * Only use `square` list-style after the first two levels.
 */
/**
 * Ordered list item counters are managed with CSS, since all list nesting is
 * purely visual.
 */
/* @noflip */
/* @noflip */
/**
 * Counters are reset in JavaScript. If you need different counter styles,
 * override these rules. If you need more nesting, create your own rules to
 * do so.
 */

/**
 * Replace `$search` with `$replace` in `$string`
 * @param {String} $string - Initial string
 * @param {String} $search - Substring to replace
 * @param {String} $replace ('') - New value
 * @return {String} - Updated string
 */
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;

  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }

  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }

  @if $digits > 0 {
    @for $i from 1 through $digits {
      $n: $n * 10;
    }
  }

  $value: 0;

  @if $mode == round {
    $value: round($number * $n) / $n;
  } @else if $mode == ceil {
    $value: ceil($number * $n) / $n;
  } @else if $mode == floor {
    $value: floor($number * $n) / $n;
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    $value: $number;
  }

  @if (str-index(qoute($value), '.')) {
    @return $value;
  } @else {
    @return ($value)+'.0';
  }
}

@mixin attr-x(
  $attr,
  $attr-count: 10,
  $attr-steps: 10,
  $unit: '%',
  $decimal: 0,
  $attr-type: '-',
  $attr-value-steps: 1
) {
  $attr-list: null;

  @if $attr-type == '-' {
    @for $i from 1 through $attr-count {
      $attr-value: $attr-steps * $i;
      @if ($decimal != 0) {
        $attr-value: decimal-round($attr-steps * $i, $decimal);
      }

      .#{$attr}-#{$attr-value} {
        @if $attr == 'intent-left' {
          margin-left: #{$attr-value}#{$unit};
        } @else if $attr == 'intent-right' {
          margin-right: #{$attr-value}#{$unit};
        } @else {
          #{$attr}: #{$attr-value}#{$unit};
        }
      }

      $attr-list: append($attr-list, unquote('.#{$attr}-#{$attr-value}'), comma);
    }
  } @else {
    @for $i from 1 through $attr-count {
      $attr-value: $attr-steps * ($attr-value-steps * $i) * 100;
      $attr-label: str-replace(quote(decimal-round($attr-value-steps * $i, $decimal)), '.', '-');

      &#{$attr-type}#{$attr-label} {
        #{$attr}: #{$attr-value}#{$unit};
      }

      $attr-list: append($attr-list, unquote('&#{$attr-type}#{$attr-label}'), comma);
    }
  }
}

.draft-body {
  --zoom : 1;
  .sidebar {
    display: none;
  }

  .header {
    display: none;
  }

  .draft {
    .editor {
      .image-component {
        .img-wrp {
          display: block;
        }
      }
    }
  }

  .editor {
    .line-height {
      @include attr-x('line-height', 180, 0.28, 'px', 1, '__', 0.1);
    }

    .editor-align-right {
      .public-DraftStyleDefault-block {
        text-align: right !important;
      }
    }

    .editor-align-left {
      .public-DraftStyleDefault-block {
        text-align: left !important;
      }
    }

    .editor-align-center {
      .public-DraftStyleDefault-block {
        text-align: center !important;
      }
    }

    .editor-align-justify {
      .public-DraftStyleDefault-block {
        text-align: justify !important;
      }
    }

    .editor-direction-ltr {
      .public-DraftStyleDefault-block {
        direction: ltr !important;
      }
    }

    .editor-direction-rtl {
      .public-DraftStyleDefault-block {
        direction: rtl !important;
      }
    }

    .DraftEditor-root {
      height: inherit;
      text-align: initial;
      position: relative;
    }

    .DraftEditor-editorContainer {
      height: inherit;
      text-align: initial;
      background-color: rgba(255, 255, 255, 0);
      border-left: 0.1px solid transparent;
      position: relative;
      z-index: 1;
    }

    .public-DraftEditor-content {
      height: inherit;
      text-align: initial;
      outline: none;
      white-space: pre-wrap;
    }

    .public-DraftEditor-block {
      position: relative;
    }

    .DraftEditor-alignLeft {
      .public-DraftStyleDefault-block {
        text-align: left;
      }

      .public-DraftEditorPlaceholder-root {
        left: 0;
        text-align: left;
      }
    }

    .DraftEditor-alignCenter {
      .public-DraftStyleDefault-block {
        text-align: center;
      }

      .public-DraftEditorPlaceholder-root {
        margin: 0 auto;
        text-align: center;
        width: 100%;
      }
    }

    .DraftEditor-alignRight {
      .public-DraftStyleDefault-block {
        text-align: right;
      }

      .public-DraftEditorPlaceholder-root {
        right: 0;
        text-align: right;
      }
    }

    .public-DraftEditorPlaceholder-root {
      color: #9197a3;
      position: absolute;
      z-index: 0;
    }

    .public-DraftEditorPlaceholder-hasFocus {
      color: #bdc1c9;
    }

    .DraftEditorPlaceholder-hidden {
      display: none;
    }

    .public-DraftStyleDefault-block {
      position: relative;
      white-space: pre-wrap;
    }

    .public-DraftStyleDefault-ltr {
      direction: ltr;
      text-align: left;
    }

    .public-DraftStyleDefault-rtl {
      direction: rtl;
      text-align: right;
    }

    [class*='listLTR'] {
      direction: ltr;
    }

    [class*='listRTL'] {
      direction: rtl;
    }

    .public-DraftStyleDefault-ul {
      padding: 0;
      margin-bottom: 0;
      list-style-type: none;
      counter-reset: ol0;
    }

    .public-DraftStyleDefault-ol {
      padding: 0;
      margin-bottom: 0;
      list-style-type: none;
      counter-reset: ol0;
      margin-left: 18px;
    }

    [class*='-orderedListItem'],
    [class*='-unorderedListItem'] {
      position: relative;
    }

    [class*="-ordered-list-item"][class*="-orderedListItem"],
    // [class*="-unordered-list-item"][class*="-unorderedListItem"],
    [class*="-unordered-list-item"][class*="-orderedListItem"],
    [class*="-ordered-list-item"][class*="-unorderedListItem"] {
      list-style-type: none;
      &[class*='listLTR'] {
        margin-left: 0;
        padding-left: 10px;
      }
      &::before {
        counter-increment: ol0;
        content: counter(ol0, number) '. ';
      }

      &[class*='depth1'] {
        &[class*='listRTL'] {
          margin-right: 3.5em !important;
          margin-left: 0;
        }
        &::before {
          counter-increment: ol1;
          content: counter(ol0) '.' counter(ol1) '. ';
          min-width: 35px;
        }
      }

      &[class*='depth0'] + [class*='depth2'] {
        counter-increment: ol1;
      }
      &[class*='depth0'] + [class*='depth3'] {
        counter-increment: ol2;
      }
      &[class*='depth0'] + [class*='depth4'] {
        counter-increment: ol3;
      }

      &[class*='depth1'] + [class*='depth3'] {
        counter-increment: ol2;
      }
      &[class*='depth2'] + [class*='depth4'] {
        counter-increment: ol3;
      }

      &[class*='depth2'] {
        &[class*='listLTR'] {
          margin-left: 92px;
        }
        &[class*='listRTL'] {
          margin-right: 4.5em !important;
          margin-left: 0;
        }
        &::before {
          counter-increment: ol2;
          content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '. ';
          min-width: 40px;
        }
      }

      &[class*='depth3'] {
        &[class*='listLTR'] {
          margin-left: 136px;
        }
        &[class*='listRTL'] {
          margin-right: 8em !important;
          margin-left: 0;
        }
        &::before {
          counter-increment: ol3;
          content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' counter(ol3) '. ';
          min-width: 60px;
        }
      }

      &[class*='depth4'] {
        &[class*='listLTR'] {
          margin-left: 184px;
        }
        &[class*='listRTL'] {
          margin-right: 12em !important;
          margin-left: 0;
        }
        &::before {
          counter-increment: ol4;
          content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' counter(ol3) '.' counter(ol4)
            '. ';
          min-width: 78px;
        }
      }
    }

    [class*='alpha'][class*='-orderedListItem'],
    [class*='alpha'][class*='-unorderedListItem'] {

      &[class*='listRTL'] {
        margin-right: 1.5em;
        margin-left: 0;
      }

      &::before {
        counter-increment: ol0;
        content: counter(ol0, upper-alpha) '. ';
      }

      &[class*='depth1'] {
        &[class*='listRTL'] {
          margin-right: 3.5em;
          margin-left: 0;
        }
        &::before {
          counter-increment: ol1;
          content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '. ';
        }
      }

      &[class*='depth2'] {
        &[class*='listLTR'] {
          margin-left: 92px;
        }
        &[class*='listRTL'] {
          margin-right: 5.5em;
          margin-left: 0;
        }
        &::before {
          counter-increment: ol2;
          content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '.'
            counter(ol2, upper-alpha) '. ';
        }
      }

      &[class*='depth3'] {
        &[class*='listLTR'] {
          margin-left: 136px;
        }
        &[class*='listRTL'] {
          margin-right: 8.5em;
          margin-left: 0;
        }
        &::before {
          counter-increment: ol3;
          content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '.'
            counter(ol2, upper-alpha) '.' counter(ol3, upper-alpha) '. ';
        }
      }

      &[class*='depth4'] {
        &[class*='listLTR'] {
          margin-left: 184px;
        }
        &[class*='listRTL'] {
          margin-right: 13em;
          margin-left: 0;
        }
        &::before {
          counter-increment: ol4;
          content: counter(ol0, upper-alpha) '.' counter(ol1, upper-alpha) '.'
            counter(ol2, upper-alpha) '.' counter(ol3, upper-alpha) '.' counter(ol4, upper-alpha)
            '. ';
        }
      }
    }

    [class*='-unorderedListItem'],
    [class*='-orderedListItem'] {
      &[class*='listLTR'] {
        margin-left: 1.5em;
      }

      &[class*='-unordered-list-item'] {
        list-style-type: disc;
        &[class*='depth1'] {
          list-style-type: circle;
        }

        &[class*='depth2'],
        &[class*='depth3'],
        &[class*='depth4'] {
          list-style-type: square;
        }
      }

      &[class*='list-item-hyphen'] {
        list-style-type: none !important;

        &::before {
          counter-increment: ol0;
          content: url('https://dr08qaykn9isr.cloudfront.net/images/Webp.net-resizeimage+(1).png') !important;
          left: -5px !important;
        }
      }

      &[class*='list-item-check'] {
        list-style-type: none !important;

        &::before {
          counter-increment: ol0;
          content: url('https://dr08qaykn9isr.cloudfront.net/images/Webp.net-resizeimage.png') !important;
          left: -10px !important;
        }
      }

      &[class*='depth1'] {
        &[class*='listLTR'] {
          margin-left: 45px;
        }
        &[class*='listRTL'] {
          margin-right: 3em !important;
        }
        &::before {
          counter-increment: ol1;
          left: -30px;
          width: auto;
        }
      }

      &[class*='depth2'] {
        &[class*='listLTR'] {
          margin-left: 72px;
        }
        &[class*='listRTL'] {
          margin-right: 4.5em !important;
        }
        &::before {
          counter-increment: ol2;
          left: -55px;
          width: auto;
        }
      }

      &[class*='depth3'] {
        &[class*='listLTR'] {
          margin-left: 96px;
        }
        &[class*='listRTL'] {
          margin-right: 6em !important;
        }
        &::before {
          counter-increment: ol3;
        }
      }

      &[class*='depth4'] {
        &[class*='listLTR'] {
          margin-left: 120px;
        }
        &[class*='listRTL'] {
          margin-right: 7.5em !important;
        }
        &::before {
          counter-increment: ol4;
          left: -90px;
          width: auto;
        }
      }
    }

    [class*='-orderedListItem'][class*='listLTR'],
    [class*='-unorderedListItem'][class*='listLTR'] {
      &::before {
        position: absolute;
        text-align: left;
        left: 0;
        transform: translateX(-100%);
        min-width: 17px;
      }
    }

    [class*='-orderedListItem'][class*='listRTL'],
    [class*='-unorderedListItem'][class*='listRTL'] {
      &:before {
        position: absolute;
        right: -30px;
        text-align: left;
        width: auto;
      }
    }


    [class*='depth1'].public-DraftStyleDefault-reset {
      counter-reset: ol1;
    }

    [class*='depth2'].public-DraftStyleDefault-reset {
      counter-reset: ol2;
    }

    [class*='depth3'].public-DraftStyleDefault-reset {
      counter-reset: ol3;
    }

    [class*='depth4'].public-DraftStyleDefault-reset {
      counter-reset: ol4;
    }

    .simple-section {
      &::after {
        pointer-events: none;
        transition: opacity 0.4s;
        position: absolute;
        width: 100%;
        height: 100%;
        content: ' ';
        background-color: #000;
        opacity: 0;
        z-index: 3;
        left: 0;
        top: 0;
      }

      &.animate-simple-section::after {
        opacity: 0.6;
      }
    }

    .editor-header {
      padding: 0;
      z-index: 10;
    }

    .editor-layout {
      .editor-edit-section {
        direction: rtl;
        background: $light-color;
        height: calc(100vh - 64px);
        width: 100%;
        padding-top: 48px;
        position: relative;

        .editor-wrapper {
          @media screen and (min-width:992px) {
            @include custom-scrollbar;
          }
          
          overflow-y: auto;
          overflow-x: auto !important;
          width: 100%;
          height: calc(100dvh - 112px);
          z-index: 11;
          position: relative;
          & > div {
            position: relative;
            direction: ltr;
          }

          
          .draft-editor-wrapper {
            margin: 0 auto;
            width: calc(100% - 36px);

            @media screen and (min-width:992px) {
              width: calc(900px * (var(--zoom, 1) / 100));
              max-width: calc(900px * (var(--zoom, 1) / 100));
            }
          }
        }

        @include lg {
          overflow-y: overlay;
          @supports (-moz-appearance: none) {
            overflow-y: auto;
          }

          & > div:last-child {
            width: 100% !important;
            padding: 0 1em;
            & > div:first-child,
            .draft-editor {
              width: 100% !important;
              .editor-container {
                width: 100% !important;
              }
            }
          }
        }

        .rich-editor-header {
          background-color: $header-background-color;
          height: $header-height;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: absolute;
          left: 0;
          top: 0;
          border-bottom: 1px solid $border-color-tab;
          width: 100%;
          z-index: 11;
          direction: initial;

          .rich-editor-toolbar {
            font-size: 20px;
            line-height: 28px;
            position: relative;
            left: 20px;
            right: auto;
            color: $primary-color-dark;
            font-weight: 400;
            letter-spacing: 0;
          }
          
          @media screen and (min-width: 1100px) {
            justify-content: center;
          }


      

          .proposal-title {
            text-align: center;
            color: $primary-color-text;
            font-size: $large-text-font-size;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 75px;
            width: calc(100vw - 30px);
            max-width: 850px;
          }

          @media screen and (min-width: 1100px) {
            justify-content: center;

            .proposal-title {
              padding-left: 0;
            }
          }
        }

        .rich-editor .rich-editor-header{
          z-index: 12;
        }

        .editor-container {
          background: $light-color;
        }

        .draft-editor {
          background: $white-color;
          margin: 0 auto;
          margin-top: 3em !important;
          margin-bottom: 6em !important;
          max-width: 900px;

          .editor-align-right {
            text-align: right !important;
          }

          .editor-align-left {
            text-align: left !important;
          }

          .editor-align-center {
            text-align: center !important;
          }

          .editor-align-justify {
            text-align: justify !important;
          }

          .editor-direction-ltr {
            direction: ltr !important;
          }

          .editor-direction-rtl {
            direction: rtl !important;
          }

          @include attr-x('intent-left', 26, 1, 'em');
          @include attr-x('intent-right', 26, 1, 'em');


          blockquote {
            padding: 10px 20px;
            border-left: 5px solid #e2e2e2;
            font-size: 18px;
            line-height: 22px;
          }

          @media screen and (max-width:767px) {
            margin-top: 90px !important;
          }
        }
      }

      .drag-element-placeholder {
        background: $section-background;
        border: 2px dashed $section-color;
        color: $section-color;
        font-size: 12px;
        padding: 15px 34px;
        text-align: center;
        border-radius: 4px;
        position: absolute;
        width: calc(100% - 24px);
        height: calc(100% - 24px);
        top: 0;
        z-index: 1;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .zoom-container {
    direction: ltr;
    position: fixed;
    left: 15px;
    bottom: 15px;
    display: flex;
    z-index: 13;
    &.zoom-container-expanded {
      
      @media screen and (min-width: 1500px) {
        left: 265px;
      }
    }
  }


  .editor-edit-section {
    .proposal-headersection,
    .simple-section-title-block,
    .simple-section-content,
    .proposal-signaturesection-content,
    .proposal-signaturesection {
      .rich-editor-header {
        position: fixed !important;
        top: 64px !important;
        left: 65px!important;
        overflow-x: auto;
        width: calc(100% - 70px)!important;
        .rich-editor-toolbar{
          width: 1000px;
        }

        @media screen and (min-width: 1000px){
          overflow-x: unset;
          width: calc(100% - 114px)!important;
          width: auto !important;
          left: calc(50% + 5px) !important;
          transform: translateX(-50%);

          .rich-editor-toolbar{
            width: auto;
          }
        }
    
        @media screen and (min-width: 1300px){
          left: calc(50% - 12.5%) !important;
        }
      }
    }
  }

  .content-table-active {
    @media screen and (min-width: 1500px){
      .editor-edit-section {
        .proposal-headersection,
        .simple-section-title-block,
        .simple-section-content,
        .proposal-signaturesection-content,
        .proposal-signaturesection {
          .rich-editor-header {
            left: calc(50% - 13% + 160px) !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 499.9px) {
    // below 500px make full width for signarture buttons
    .proposal-signaturesection > .ant-row > .ant-col > .ant-row > .ant-col{
      max-width: 100%;
      flex: 0 0 100%;
      &:not(:first-child){
        margin-top:20px;
      }
    }
  }
  @media screen and (max-width: 767.9px) {
    // below 768px make full width for cover section columns
    .proposal-headersection{
      & > .ant-row > .ant-col{
        max-width: 100%;
        flex: 0 0 100%;
        &:not(:first-child){
          margin-top:20px;
        }
      }
    }
  }
  @media screen and (max-width: 939.9px) {
    // below 940px make full width for section,signature columns
    .simple-section,
    .proposal-signaturesection{
      & > .ant-row > .ant-col{
        max-width: 100%;
        flex: 0 0 100%;
        &:not(:first-child){
          margin-top:20px;
        }
      }
    }
  }
}

.language-rtl {
  direction: rtl;

  &.draft-editor {
    [class*='ListItem']{
      &::before {
        left: unset!important;
        right: 0;
        transform: translateX(100%)!important;
      }
    }
  }

  .proposal-headersection {
    &.proposalheader-custom {
      .header-title-container,
      .header-section-container {
        .ant-col {
          &:last-child {
            > .proposalheaderwhite {
              .public-DraftStyleDefault-block,
              .public-DraftEditorPlaceholder-root,
              p {
                text-align: left !important;
              }
            }
          }
        }
      }
    }
  }

  .simple-section {
    .simple-section-container {
      .simple-section-content {
        figure {
          text-align: right;
        }

        .html-content {
          direction: rtl;
          text-align: right;
        }

        .form-list-item {
          text-align: right;
          .ant-checkbox-wrapper {
            float: left;
            margin-left: 0.8em;
          }
          label {
            padding-left: 0em;
            padding-right: 2em;
          }
          .input-container {
            .input {
              text-align: right;
            }
            &.handle {
              padding-right: 0em;
            }
          }
          .form-error-text {
            float: right;
            margin-right: 1.7em;
            margin-left: 0;
          }
          .editable {
            margin-right: 0.88em;
          }
          .required-editable {
            margin-right: 0.2em;
          }
        }

        .form-editor-fields-row {
          .ant-list > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items {
            direction: rtl;
            .ant-list-item {
              float: right;
            }
          }
        }
        .form-error-text {
          float: left;
          margin-left: 1.3em;
        }
      }
    }
  }

  .section-wrapper {
    .cover-buttons {
      direction: ltr;
      right: 8px;
      left: auto;
      &.second {
        right: 238px;
      }
      &.third {
        right: 419px;
      }
    }

    .section-action-buttons {
      direction: ltr;
      left: auto;
      right: 8px;
    }

    .section-order-buttons {
      direction: ltr;
      right: auto;
      left: 8px;
    }

    .new-section-container {
      .new-section-button {
        direction: ltr;
      }
    }

    .signature-btn-wrapper-option {
      direction: ltr;
      right: auto;
      left: 011px;
    }

    .rich-editor {
      .public-DraftStyleDefault-ltr {
        direction: rtl;
        text-align: right;
      }

      .rich-editor-components-action-buttons {
        direction: ltr;
        left: 0;
      }

      .gallery-container {
        direction: ltr;
      }

      .public-DraftEditor-content {
        .public-DraftStyleDefault-ul,
        .public-DraftStyleDefault-ol {
          [class*='-unordered-list-item'],
          [class*='-ordered-list-item'],
          [class*='-unorderedListItem'],
          [class*='-orderedListItem'] {
            direction: rtl;
            margin-right: 1.5em;


            &::before {
              right: -13px;
            }
          }

          [class*='depth0'][class*='listLTR'],
          [class*='depth0'][class*='listRTL'] {
            margin-left: 0 !important;
            margin-right: 1.5em !important;
          }

          [class*='depth1'][class*='listLTR'],
          [class*='depth1'][class*='listRTL'] {
            margin-left: 0 !important;
            margin-right: 64px !important;
            padding-left: 0;
            padding-right: 10px;

            &[class*='alpha'] {
              margin-right: 70px !important;
            }
            &[class*='unorderedListItem'] {
              margin-left: 0 !important;
              margin-right: 50px !important;
            }
            &::before {
              right: -5px;
            }
            .public-DraftStyleDefault-ltr {
              margin-left: 0px;
              width: auto;
            }
          }

          [class*='depth2'][class*='listLTR'],
          [class*='depth2'][class*='listRTL'] {
            margin-left: 0 !important;
            margin-right: 120px !important;
            padding-left: 0!important;
            padding-right: 6px;

            &[class*='alpha'] {
              margin-right: 130px !important;
            }
            &[class*='unorderedListItem'] {
              margin-left: 0 !important;
              margin-right:88px !important;
              padding-right: 5px;
            }
          }

          [class*='depth3'][class*='listLTR'],
          [class*='depth3'][class*='listRTL'] {
            margin-left: 0 !important;
            margin-right: 170px !important;
            padding-left: 0 !important;
            &[class*='alpha'] {
              margin-right: 180px !important;
            }
            &[class*='unorderedListItem'] {
              margin-left: 0 !important;
              margin-right: 118px !important;
              padding-right: 5px;
            }
          }

          [class*='depth4'][class*='listLTR'],
          [class*='depth4'][class*='listRTL'] {
            margin-left: 0 !important;
            margin-right: 218px !important;
            padding-left: 0 !important;
            &[class*='alpha'] {
              margin-left: 0 !important;
              margin-right: 234px !important;
            }
            &[class*='unorderedListItem'] {
              margin-left: 0 !important;
              margin-right: 144px !important;
              padding-right: 5px;
            }
          }
        }

        .rich-editor-components-wrapper {
          .texblockbody {
            ol,
            ul {
              padding-right: 1em;

              .super-li {
                text-align: right;
                padding-right: 0.5em;
              }
            }
            .pkg-name {
              text-align: right;
            }
          }

          table tbody tr {
            .grey-table-data-cell,
            .name {
              text-align: right;

              .table-row-action {
                left: 3.2em;
              }
            }
            .price {
              text-align: left;
            }
            .time {
              text-align: left;
            }
            &.timerow {
              td:nth-child(1) {
                flex: 0.7;
              }
              td:nth-child(2) {
                flex: 0.3;
              }
            }
          }

          .rich-editor-components-draggable-icon {
            right: -30px;
            left: auto;
          }

          .html-content {
            direction: rtl;
            text-align: right;
          }
        }
      }
    }

    .new-section {
      .ant-row .ant-col .ant-card .ant-card-body {
        direction: ltr;
      }
    }
  }

  &.upload-signature {
    .ant-modal-content {
      .title {
        text-align: right;
      }
      .radio-wrapper {
        direction: rtl;
        text-align: right;
        .ant-radio-wrapper {
          margin-right: 5px;
          &:last-child {
            margin-right: 5px;
          }
          &:first-child {
            margin-right: 0;
          }
        }
      }
      .accept-text {
        text-align: right;
      }
    }
  }

  @include xs {
    &.upload-signature {
      .ant-modal-content {
        .radio-wrapper {
          .ant-radio-wrapper {
            padding: 5px !important;
            &:last-child {
              margin-right: 5px !important;
            }
            &:first-child {
              margin-right: 0 !important;
            }
            & > span:last-child {
              padding-right: 10px !important;
              padding-left: 10px !important;
            }
          }
        }
      }
    }
  }
}

.editor-color-picker .ant-popover-inner-content {
  padding: 0 !important;
}



.droppable-editor,
.calculate-droppable-height {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    background-color: $section-color;
  }
}

.droppable-editor-notiv {
  position: relative;
  &::after {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    background-color: transparent;
  }
}

.rich-copy-spinner {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  background-color: $loader-overlay-background;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: fixed !important;
}


