@import 'styles/theme.scss';

.dashboard-modal {
  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-body {
    padding: 20px 20px 20px;
    text-align: center;

    h1 {
      font-weight: 600;
      font-size: 24px;
      color: $primary-color-dark;
    }

    .sub-heading {
      font-size: 16px;
      color: #010032;
      margin-bottom: 20px;
    }

    .color-divider {
      background: $secondary-color;
      width: 32px;
      height: 1px;
      padding: 0 10px;
      margin: 10px auto;
    }

    .type-selector {
      cursor: pointer;

      h2 {
        font-size: 1.25em;
        font-weight: 600;
        margin-bottom: 0;
        font-size: 18px;
      }

      .circle {
        height: 160px;
        width: 160px;
        border-radius: 50%;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 37px;
      }

      .explainer {
        font-weight: 300;
        font-size: 0.875em;
        margin: 0;

        b {
          font-weight: 600;
        }
      }

      .box {
        box-sizing: border-box;
        border-radius: 4px;
        background: $white-color;
        border: 1px solid $light-link-border-color;
        padding: 10px;
        margin-bottom: 20px;
        position: relative;

        .explainer {
          color: #010032;
          opacity: 0.8;
          font-size: 14px;
        }

        h2 {
          color: $primary-color-dark;
        }
        &.from-template {
          h2 {
            padding: 0 20px;
          }
        }

        .circle {
          background: $white-color;
          box-shadow: 0px 13px 45px rgba(186, 186, 187, 0.25);
        }

        img {
          width: 50%;
          max-width: 200px;
        }

        .lang-info {
          position: absolute;
          right: 7px;
          top: 5px;
          font-size: 1.125em;
        }

        &:hover {
          background: $primary-color;
          border: 1px solid $primary-color;
          box-shadow: 0px 20px 25px rgba(47, 44, 136, 0.25);

          h2 {
            color: $white-color;
          }

          .explainer {
            color: $white-color;
          }
        }
      }
    }

    .modal-container-import {
      margin-top: 32px !important;

      .cursor-import {
        cursor: pointer;
        color: $primary-color-light !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        .plus-import {
          font-size: 16px;
          font-weight: bold;
        }

        &:hover {
          background: rgba(199, 188, 228, 0.2);
          border-radius: 4px !important;
          padding: 8px 12px !important;
          color: $primary-color !important;
        }

        .plus-import {
          margin-top: 15px;
          margin-right: 6px !important;
        }
      }
    }
  }

  @include rwd-min(360) {
    .ant-modal-body{
      .type-selector {
        display: flex;
        justify-content: center;
        gap: 10px;
        .box {
          width: 50%;
          h2 {
            min-height: 57px;
          }
          img {
            width: 80%;
          }
        }
      }
    }
  }

  @include rwd-min(490) {
    .ant-modal-body{
      .type-selector {
        .box{
          h2 {
            font-size: 20px;
            min-height:40px;
          }
        }
      }
    }
  }

  @include rwd-min(576) {
    .ant-modal-body {
      padding: 50px;

      .sub-heading {
        margin-bottom: 30px;
      }

      .box:nth-child(1) {
        margin-right: 15px;
      }

      .box:nth-child(2) {
        margin-left: 15px;
      }
    }
  }


  @include rwd-min(992) {
    .ant-modal-body {
      padding: 50px 80px;

      .type-selector {
        gap: 30px;

        .box {
          img {
            width: 100%;
            max-width: 500px;
          }
        }
      }
    }
  }
}

.lang-tooltip {
  .ant-tooltip-inner {
    padding: 5px 15px;
    font-size: 0.875em;
    font-weight: normal;
  }
}

.anticon.close-icon {
  &:hover {
    background: $light-color;
    border-radius: 50%;
    padding: 4px 4px;
  }
}