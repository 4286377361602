@import "styles/theme.scss";

.code-add-modal {
  .title {
    margin-top: 0px !important;
  }

  .code-link {
    color: $primary-color-text !important;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    margin-top: 15px;

    a {
      text-decoration: underline;
      color: $primary-color-text !important;
      font-weight: normal;
    }

    svg {
      cursor: pointer;
    }

  }

  .ant-modal-body {
    padding: 40px 30px;

    .add-code {
      margin-top: 36px;

      p {
        color: $label-color-light;
        font-size: 14px;
        margin-bottom: 3px;
      }
      .ant-input {
        border: 1px solid #e2e3e8;
        box-sizing: border-box;
        border-radius: 4px;
        height: 120px;
        resize: none;
      }
    }
  }
  .error {
    border-color: $error-color !important;
  }
}
