@import 'styles/theme.scss';

.overflow-dropdown-list-container {
  display: flex;
  padding-right: 10px;
  .ant-dropdown-trigger {
    border-radius: 50%;
    border: 1px solid $border-color-tab;
    padding: 0.5em;
    margin: 0 1em;
    width: 2.3em;
    height: 2.3em;
  }
  & > .overflow-dropdown-background {
    background-color: #ebebf3 !important;
    border-color: transparent !important;
  }
  .ant-divider-vertical.pricing {
    height: 1.5em;
    margin: 8px 0 0 15px;
    border-left: 1px solid #D9D9D9;
  }
}

.ant-dropdown .ant-dropdown-menu.overflow-dropdown-list-menu-dynamic {
  background: transparent;
  box-shadow: none;
  margin-left: -1em;
  .overflow-dropdown-list-menu-item-dynamic {
    button {
      padding: 4px 12px;
      display: flex;
      &.button-disabled {
        background-color: $input-disabled-color;
        cursor: not-allowed;
        border-color: $border-color-base !important;
      }
    }
    span {
      color: $label-color-light;
      &.anticon {
        padding: 4px 0;
      }
    }
    &:hover {
      background-color: transparent;
    }
  }
}
