@import "styles/theme.scss";


.layout-menu {
  background-color: $white-color !important;
  padding: 0 var(--section-padding);
  margin-bottom: 100px;

  .ant-divider {
    border-top-color: $tab-border;
    &.ant-divider-horizontal.ant-divider-with-text-left::before {
      width: 0%;
    }
    .ant-divider-inner-text {
      padding: 0 0.5em 0 0;
      text-transform: capitalize;
      h5 {
        font-weight: 600;
        font-size: $sub-font-size;
        letter-spacing: 0.04em;
        color: $label-color-light;
        margin-top: 0em;
      }
    }
  }

  .layout-menu-input-wrapper {
    .settings-label {
      display: flex;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: $label-color-light;
      width: 100%;

      span {
        text-transform: capitalize;
      }
      & > span:first-child {
        flex-grow: 1;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-radio-wrapper {
    padding: 4.8px;
    border-radius: 4px;
    min-width: 85px;
    width: 100%;
    font-size: 12.2px !important;
    color: $primary-color-text !important;
    border: 1px solid $tab-border;
    text-overflow: ellipsis !important;
    overflow-x: hidden !important;
    &.ant-radio-wrapper-checked {
      border: 1px solid $primary-color-light;
    }
  }
  .reset-item {
    margin-top: 30px;
  }
}
