@import 'styles/theme.scss';
.split-d1 {
  .ant-tree,
  .shared-folder,
  .starred-folder {
    margin-right: 10px;
  }

  .ant-tree-treenode {
    width: 100%;
    height: 39px;
    align-items: center !important;
    padding-bottom: 0 !important;

    .edit-icon > svg {
      width: 18px;
    }
  }

  .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tree-treenode {
    border-radius: 4px;
    transition: 0.4s background-color;
    .ant-tree-node-content-wrapper:hover{
      background-color: transparent!important;
    }

    &.ant-tree-treenode-selected,
    &:hover {
      width: 100%;
      background-color: $light-color;
    }
    &:focus {
      width: 100%;
      background-color: $light-color;
    }
  }

  .ant-tree-node-selected,
  .ant-tree-show-line .ant-tree-switcher {
    background-color: transparent !important;
  }

  .ant-tree-node-content-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    .ant-tree-title {
      flex-grow: 1;
      .content-library-tree-title {
        width: 100%;
        display: flex;
        align-items: center;
        & > :first-child {
          flex-grow: 1;
          overflow: hidden;
          height: 1.8em;
          width: 5em;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .sidemenu-list {
    .shared-folder,
    .starred-folder {
      padding: 0.7em 2.1em;
      cursor: pointer;
      &:hover,
      &:focus,
      &.shared-folder-active,
      &.starred-folder-active {
        background-color: $tab-background;
      }
    }

    .ant-skeleton {
      width: calc(100% - 10px);
      padding: 0.2em 0em;
      .ant-skeleton-input {
        height: 39px;
        border-radius: 4px;
      }
    }
  }

  .ant-tree-show-line .ant-tree-indent-unit::before {
    bottom: 0 !important;
  }

  .tree-node-search-input {
    width: calc(100% - 10px);
    &:focus,
    &:hover {
      border-color: $border-color-base;
      box-shadow: none;
    }
  }
}

.delete-confirm {
  padding: 24px;
  max-width: 290px;
  p {
    font-size: 0.9em;
  }
  .buttons {
    text-align: center;
    margin-top: 2em;
    .ant-btn {
      border-radius: 4px;
      font-weight: 400;
      font-size: 0.8em;
      color: $primary-color-text;
      background: $light-color;
      border: unset;
      width: 88px;
      &:hover {
        opacity: 0.8;
      }
    }
    .delete-btn-small {
      background-color: $secondary-color;
      color: #fff;
      box-shadow: 0px 10px 15px -4px #f15c3c66;
    }
  }
}
