@import 'styles/theme.scss';

.RichEditor-atomic {
  width: 100%;
  text-align: left;
  position: relative;
}

.v3-proposalblock-image {
  padding: 0px 0px 0px;

  .simple-section-content {
    width: 100%;
  }
}

.language-rtl {
  .nobreak-secondary {
    .nobreak-secondary-checkbox {
      margin-left: 12px;
      padding-left: 25px;
    }
  }

  .pricing-table-heading {
    .name {
      text-align: right;
    }

    .price {
      text-align: left !important;
    }
  }
}

.nobreak-secondary {
  display: flex;

  .nobreak-secondary-checkbox-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .nobreak-secondary-checkbox:hover input~.checkmark {
    background-color: #ccc;
  }

  .nobreak-secondary-checkbox-checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 1px #e7e4e4;

    @media screen and (max-width: 768px) {
      top: 0;
    }

    .mobile-screen & {
      top: 0;
    }

    .language-rtl & {
      right: 12px;
      left: auto;

      @media screen and (max-width: 768px) {
        right: 0;
      }
    }
  }

  .nobreak-secondary-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-top: 10px;
    font-size: 22px;
    user-select: none;
    cursor: pointer;

    &.has-top-padding {
      display: block;
      top: -8px;

      @media screen and (max-width: 768px) {
        top: -4px;
      }

      .mobile-screen & {
        top: -4px;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    input:checked~.nobreak-secondary-checkbox-checkmark {
      background-color: #2196f3;
    }

    input:disabled:not(input:checked)~.nobreak-secondary-checkbox-checkmark {

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1px;
        height: 20px;
        background-color: lightgrey;
        transform: rotate(45deg) translate(-50%, -50%);
        transform-origin: top left;
        content: '';
        opacity: 1;
        display: block;
        border-color: lightgrey;
      }

      &::after {
        transform: rotate(-45deg) translate(-50%, -50%);
      }
    }

    input:disabled~.nobreak-secondary-checkbox-checkmark {
      cursor: not-allowed;
    }

    input:checked~.nobreak-secondary-checkbox-checkmark:after {
      display: block;
    }

    .nobreak-secondary-checkbox-checkmark:after {
      left: 7px;
      top: 3px;
      width: 4px;
      height: 9px;
      border: solid white;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.texblockbody {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;

  .pkg-name {
    margin-bottom: 10px;

    b {
      u {
        color: #000;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }

  p {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin-bottom: 0;
    font-weight: 300;
    line-height: 1.4;
    width: 70%;
    &.item-description {
      font-size: 0.8em;
    }
  }

  .pkg-name {
    font-size: 24px;
  }

  .pkg-1 {
    padding-top: 0px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;

    &.texblocktotals {
      box-shadow: none;
    }

    box-shadow: 0 0 0 1px #ebebeb;

    .texblockbodyinner {
      box-shadow: 0 0 0 1px #ebebeb;
    }
  }

  .texblocktotals {
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

    .mobile-screen & {
      font-size: 14px;
    }

    tbody {
      tr {
        background-color: transparent !important;
        border-top: 0;
        border-bottom: 0;

        &:last-child:not(:only-child) {
          border-top: 1px solid #ebebeb;
        }

        &:nth-child(even):not(:last-child):not(:only-child) {
          border-top: 0;
          border-bottom: 0;
        }

        td {
          color: #848484;
          padding: 0 18px 15px !important;

          &.name {
            white-space: nowrap;
          }

          &.unit-value {
            font-weight: 500;
          }

          &.unit-percent {
            span {
              direction: ltr;
            }
          }
        }

        &.totalrow {
          td {
            font-weight: 600;
            color: #000;
            padding: 18px !important;
          }
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: fit-content !important;

    &.has-totals {
      margin-bottom: 20px;
    }

    th,
    td {
      border: none !important;
    }

    tr {
      &:nth-child(even) {
        background-color: #fafafa;
      }

      &:nth-child(even):not(:last-child):not(:only-child) {
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
      }

      &:nth-child(even):last-child:not(:only-child) {
        border-top: 1px solid #ebebeb;
      }

      &.finalrow:not(:only-child) {
        border-top: 1px solid #ebebeb;
      }
    }

    .item-title {
      line-height: 1.4;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        left: 0 !important;
        line-height: 1.4;
      }

      .mobile-screen & {
        font-size: 14px;
        left: 0 !important;
        line-height: 1.4;
      }
    }

    .item-description {
      @media screen and (max-width: 768px) {
        margin-top: 5px;
        font-size: 12px !important;
        width: 100%;
      }

      .mobile-screen & {
        margin-top: 5px;
        font-size: 12px !important;
        width: 100%;
      }
    }

    .pricing-table-heading tr {
      background-color: #fff !important;
      font-weight: 500;
      font-size: 14px !important;
      .price{
        min-width: 130px !important;
      }
      td {
        color: #666666;
        font-weight: 500 !important;
        padding: 6px 18px !important;
        text-transform: uppercase !important;
      }
    }

    .pricerow,
    .tablerow,
    .sumrow {

      .name,
      .quantity,
      .price,
      .time {
        word-break: break-word;
        padding: 16px;
        min-height: 46px;
        vertical-align: top;
      }

      .name {
        @media screen and (max-width: 768px) {
          word-break: normal;
        }
      }

      .total {
        font-weight: 500;
      }

      .price {
        text-transform: none;

        .price-amount {
          font-weight: 600 !important;
          display: inline-flex;
          align-items: baseline;

          del {
            font-size: 0.75em;
            color: #848484;
            font-weight: 500 !important;
          }

          &.long {
            white-space: normal;
            display: block;
          }

          @media screen and (max-width: 768px) {
            font-size: 13px;
          }

          .mobile-screen & {
            font-size: 13px;
          }
        }

        &>.price-discount-style {
          font-size: 0.75em;
          color: rgb(132, 132, 132);

          @media screen and (max-width: 768px) {
            line-height: 1.4;
          }

          .mobile-screen & {
            line-height: 1.4;
          }
        }
      }
    }

    .pricerow .quantity,
    .tablerow .quantity {
      text-align: center;
      word-break: keep-all;
      vertical-align: top;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }

      .mobile-screen & {
        font-size: 12px;
      }
    }

    .pricerow .price {
      text-align: right;
    }

    tr {
      width: 100%;

      td {
        padding: 3px 0;

        .item-description {
          font-size: 0.8em;
          position: relative;
        }
      }

      &.tablerow,
      &.valuerow,
      &.sumrow {
        .price {
          font-weight: 500;
          text-align: right;
          min-width: 12ch;
          // width: 1%;
          vertical-align: top;

          &:nth-child(1) {
            text-align: left;
          }
        }
      }
    }

    .discount-text {
      font-weight: normal;
    }

    .btoprow {
      border-top: 1px solid #eee;

      td {
        padding-top: 10px;
      }
    }

    .discountrow {
      font-weight: normal;

      td {
        padding-bottom: 10px;
      }
    }

    .subtrow {
      font-weight: normal;

      td {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }

    .finalrow {
      td {
        padding-bottom: 10px;
      }
    }

    .totalrow {
      font-weight: bold;
      background-color: #fff !important;

      td {
        padding-top: 10px;
      }
    }

    .discountrow,
    .taxrow,
    .subtrow {
      td {
        padding: 0 18px;
      }
    }
  }
}

.pricing, 
.deliverables {
  .texblockbody {
    table {
      width: 100% !important
    }
  }
}

.milestones{
  table .item-description {
    width:100%;
  }
}

.large-font,
.max-font {
  .texblockbody {
    table {
      width: 100% !important;

      .tablerow,
      .valuerow,
      .sumrow {
        .price {
          min-width: 150px;
          width: auto;
          white-space: unset;
        }

        .price-amount {
          white-space: unset;
          flex-wrap: wrap;
          justify-content: flex-end;
        }

        .final-amount {
          direction: initial;
          display: inline-block;
        }
      }
    }
  }
}


.max-font {
  .texblockbody table {
    font-size: 36px;
  }
}



.mobile-screen {
  .timerow {
    .name ,
    .time {
      font-size: 14px;
    }
  }

  .pricing-layout-check-box {
    position: relative !important;
    height: 20px;
    left: 0 !important;
  }
}

.language-rtl {
  .mobile-screen {
    .pricing-layout-check-box {
      position: absolute !important;
      left: auto !important;
    }

    .item-title {
      top: 4px !important;
    }
  }

  .texblockbody table {
    .pricing-table-heading tr {
      font-size: 16px !important;
    }
    .price-amount {
      direction: rtl;
    }
  }
}

.no-rtl {
  direction: initial !important;
  display: inline-flex;
}

.no-break {
  white-space: nowrap;
}

// for table in milestone section
.pricing-milestone-container.milestones {
  table {
    table-layout: unset;

    .timerow.tablerow {
      td.name {
        width: auto;
      }

      td.time {
        text-align: right;
        white-space: normal !important;
        word-break: normal;
        min-width: 72px;
        max-width: 30ch;
        width: auto;
        padding-left:0;
        &.size-2 {
          min-width: 114px;
        }
        &.size-3 {
          min-width: 146px;
        }
        &.size-4 {
          min-width: 186px;
        }
        &.size-5 {
          min-width: 230px;
        }
        &.size-6 {
          min-width: 236px;
        }
        &.size-7 {
          min-width: 300px;
        }

        .mobile-screen & {
          max-width: 50%;

          &.size-4 ,
          &.size-5 ,
          &.size-6 ,
          &.size-7 {
            min-width: 186px;
          }
        }
        @media screen and (max-width: 768px) {
          max-width: 50%;
          &.size-4 ,
          &.size-5 ,
          &.size-6 ,
          &.size-7 {
            min-width: 146px;
          }
        }

        .time-text {
          word-break: break-word;
          hyphens: auto;
        }
      }
    }
  }
}