@import 'styles/theme.scss';
.hyperlink-content {
  border-radius: 4px;
  background: $white-color;
  box-shadow: 0px 5px 20px 0px rgba(4, 3, 49, 0.15);
  position: absolute;
  z-index: 4;
  top: 30px;

  @include sm(){
    left: 0!important;
  }

  .hyperlink-btn , .hyperlink-btn:hover{
    display: flex;
    height: 32px;
    padding: 6px 26px 6px 27px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: $light-color;
    border: none;

    span {
      color: $primary-color-dark;
      font-size: 14px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
  .btn-valid,.btn-valid:hover  {
    background: #f03f3b !important;
     span {
      color: $white-color;
     }
  }
}

.hyperlink-input {
  input,
  input:focus,
  input:hover,
  .ant-input-group-addon {
    border: 1px solid $primary-color-light;
  }

  .ant-input-group-addon {
    border-right: none !important;
    border-radius: 4px 0px 0px 4px;
    padding: 0 8px;
    vertical-align: bottom;
    background-color: $white-color;
  }

  input {
    border-left: none !important;
    padding-left: 0px;
    border-radius: 0px 4px 4px 0px;
    font-family: Poppins;

    &:focus {
      box-shadow: none;
    }
  }
}

.hyperlink-input.error {
  input,
  input:focus,
  input:hover,
  .ant-input-group-addon {
    border: 1px solid $error-color;
  }
}
.hyperlink-wrappper {
  padding: 16px;
  gap: 8px;
  margin: 0 !important;
  direction: initial !important;

  .ant-col {
    padding: 0 !important;
    width: 250px;
  }
}
