@import 'styles/theme.scss';

.affiliate {
  --affiliate-padding : 10px;
  .page-header {
    margin-bottom: 2em;
  }

  .affiliate-main-content {
    background-color: $white-color;
    box-shadow: $box-shadow;
    border-radius: 4px;
    border: 1px solid transparent;
    h3 {
      color: $primary-color-dark;
      font-size: 16px;
      margin-bottom: 0;
    }
    .heading {
      margin-bottom: calc(var(--affiliate-padding)  - 10px);
    }
    .m-t-20 {
      margin-top: 20px;
    }

    .block {
      padding: var(--affiliate-padding);
    }

    .checkout {
      .checkout-left {
        border-bottom: 1px solid $tab-border;

        h3 {
          color: $primary-color-dark;
          font-size: 24px;
          margin-bottom: 10px;
        }

        p {
          color: black;
          font-size: 16px;
          margin-bottom: 0px;
        }

        h1 {
          font-weight: 600;
          font-size: 56px;
          color: $secondary-color;
          opacity: 0.8;
          margin-bottom: 10px;
          & .active {
            color: $completed-color;
          }
        }

        .ant-btn {
          width: 100%;
        }
      }

      .checkout-right {
        border-bottom: 1px solid $tab-border;
        h3 {
          color: $primary-color-dark;
          font-size: 24px;
          margin-bottom: 0px;
        }
        .heading-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 33px;
        }
        .ant-form-item {
          .ant-form-item-label label {
            color: $label-color-dark;
          }
        }
        .email-input {
          max-width: 500px;
          height: 44px;
        }
      }

      .checkout-divider {
        flex: 0;
        .ant-divider.ant-divider-horizontal {
          height: 100%;
          border-left: 1px solid $tab-border;
          border-top: none;
          margin: 0;
        }
      }

      @media (max-width: 1200px) {
        .ant-col-lg-24.checkout-divider {
          flex: 0 0 100%;
          .ant-divider {
            border-top: 1px solid $tab-border !important;
            display: -ms-flexbox;
            display: flex;
            clear: both;
            width: 100%;
            min-width: 100%;
            margin: 24px 0;
            border-left: 0;
          }
        }
      }
    }

    .link {
      border-bottom: 1px solid $tab-border;
      .heading {
        display: flex;
        align-items: center;
        h3 {
          color: $primary-color-dark;
          font-size: 24px;
          margin-bottom: 0px;
          margin-right: 40px;
        }
        span {
          color: $label-color-dark;
          font-size: 0.85em;
          letter-spacing: 0.5px;
        }
      }

      .link-input-wrapper {
        width: 100%;
        display: flex;
        margin-top: 15px;
        justify-content: space-between;
        .affiliate-link {
          height: 44px;
          outline: none !important;
          box-shadow: none !important;
          border: 0.5px solid #bebebe;
          border-radius: 4px;
          font-size: 15px;
          margin-right: var(--affiliate-padding);
        }
      }
    }

    .social-share {
      .social-icon-grp {
        color: $primary-color-dark;
        gap: 10px;
        .anticon {
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }
        .icon-wrapper {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 10px;
          border: 1px solid $light-link-border-color;
          border-radius: 4px;
          transition: 0.5s ease all;
          &:hover {
            border: 1px solid $primary-color;
          }
        }
      }
    }

    &.table-block {
      .heading {
        padding: var(--affiliate-padding);
      }
      margin-top: 2rem;
      .affiliate-table {
        overflow-x: auto;
        .ant-table-container {
          .ant-table-content {
            min-width: 500px;
            table {
              thead {
                background: $tab-dark-background;
                border-radius: 4px;
              }
              thead tr th {
                background: none;
              }
              thead tr th,
              tbody tr td {
                padding: 8px 16px;
                color: $primary-color-text;
              }
              tbody tr {
                border-radius: 4px;
                &:nth-child(2) {
                  background: #f5f6fa;
                }
                td {
                  border: none;
                  font-weight: 100;
                }
              }
            }
          }
        }
      }
      .payment-table{
        .ant-table-content {
          min-width: 500px;
        }
      }
    }
  }


  @media (min-width: 767px) {
    .social-icon-grp .ant-col {
      flex: unset;
      width: calc(50% - 5px);
    }
  }

  @media (min-width: 992px) {
    --affiliate-padding : 40px;

    .affiliate-main-content {
      h3 {
        font-size: 24px;
      }

      .checkout-left {
        border-right: 1px solid $tab-border;
      }

      .icon-wrapper {
        flex-direction: column;
      }

      .social-icon-grp {
        gap: 30px !important;
        text-align: center;
        .ant-col {
          width: calc(25% - 25px);
          .icon-wrapper {
            padding: 30px 0 !important;
            .anticon {
              height: 50px;
              width: 50px;
              margin: 0 0 20px;
            }
          }
        }
      }

      .affiliate-table {
        padding: var(--affiliate-padding);
        padding-top: 0;
      }
    }
  }
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0 !important;
  margin-right: 8px;
}
