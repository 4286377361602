@import "styles/theme.scss";

.color-picker {
  & * {
    user-select: none;
  }

  direction: ltr;
  direction: initial;
  position: absolute;
  top: 31px;
  right: -75px;
  background-color: #fff;
  color: #4a4a4a;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 12px 0px;
  border-radius: 4px;
  font-family: var(--font-family);

  &.outside-toolbar {
    position: fixed;
    top: 105px;
    left: calc(37% - 110px);
    width: 220px;
    z-index: 15;
  }

  .sketch-picker {
    box-shadow: none !important;

    label {
      user-select: none;
    }
  }

  .color-tabs {
    display: flex;
    border-bottom: 1px solid $tab-border;
    height: 34px;

    .color-tab {
      font-size: 14px;
      width: 50%;
      text-align: center;
      padding: 6px;
      border-bottom: 2px solid transparent;
      line-height: 20px;
      cursor: pointer;
      opacity: 0.6;

      &.active {
        border-bottom: 2px solid $section-color;
        opacity: 1;
      }

      &:hover {
        background-color: #03030305;
      }
    }
  }
}

.color-picker-popover {
  left: 50% !important;

  .color-picker {
    right: -110px;
    top: 0;
  }
}