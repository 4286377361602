.integration-item {
  .code-list {
    width: 98%;
    display: block;
    margin-top: 40px;

    .edit-code-wrapper {
      position: relative;
      border: 1px solid #e2e3e8;
      box-sizing: border-box;
      border-radius: 4px;

      .edit-code {
        border: none !important;
        padding: 16px !important;
        width: calc(100% - 120px);
        outline: none !important;
        box-shadow: none;
        resize: none;
      }

      .action-btn-wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      
      margin-bottom: 20px;
    }

    .integration-action {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 38, 95, 0.2);
      border-radius: 4px;
      height: 32px;
      width: 58px;
      border: none;
    }

    .edit-btn {
      margin-right: 8px;
    }

    .save-btn {
      margin-right: 8px;
      background: #f5f6fa;
    }

    .delete-btn {
      width: 32px;
      padding: 0px;
    }

    .code-items {
      .code {
        width: 80%;
      }
      display: flex;
      justify-content: space-between;
      padding: 16px;
      padding-right: 10px;
      border: 1px solid #e2e3e8;
      border-radius: 4px;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}
