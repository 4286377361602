@import 'styles/theme.scss';

.covers-library-section {
  .ant-card {
    border-radius: 4px;
    overflow: hidden;
    filter: drop-shadow(0px 2px 4px rgba(3, 20, 63, 0.2));
    .ant-card-cover {
      transform: translateY(0px);
    }
  }

  .ant-card-body {
    padding: 0;
  }

  .folder {
    margin: 16px 0;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .anticon {
      margin-right: 10px;
    }
    .folder-path-arrow {
      svg {
        width: 12px;
        height: 12px;
        path {
          fill: $primary-color-light;
        }
      }
    }
  }

  .folder-path {
    display: flex;
    align-items: center;
    padding-top: 1em;

    .path {
      flex-grow: 1;

      .folder-path-name {
        text-transform: capitalize;
        color: $primary-color-text;
        &:last-of-type {
          font-weight: bold;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
        }
      }
    }

    .folder-path-buttons {
      .secondary-btn {
        min-width: 0;
        margin-right: 10px;
        .anticon{
          transform: translateX(6px);
        }
        &.medium-btn svg {
          width: 20px;
        }
      }
    }
  }

  .prosper-button-add-folder-icon {
    margin: 1em 0;
  }
}
